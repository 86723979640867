import React, {useEffect} from "react";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";

export default function TextAreaCell(props) {

    const [value, setValue] = React.useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props])

    const updateTextAreaValue = (event) => {
        setValue(event.detail.value);
        props.logFlyerModification("edit", [{
            "id": props.id,
            "attribute": props.attribute,
            "value": event.detail.value
        }]);
    }


    return (
        <Textarea
            onChange={updateTextAreaValue}
            value={value}
            autoComplete={false}
            readOnly={props.readOnlyFlag}
        />
    );
}
