
export const NATIONAL_ACTION_OPTIONS = [
    { label: "Create", value: 1 },
    { label: "Approve", value: 2 },
    { label: "Assign Reviewer", value: 3 },
    { label: "Comment", value: 4 },
    { label: "Delete", value: 5 }
];

export const NON_NATIONAL_ACTION_OPTIONS = [
    { label: "Approve", value: 1 },
    { label: "Assign Reviewer", value: 2 },
    { label: "Comment", value: 3 },
    { label: "Delete", value: 4 }
];

export const ASSIGNEE_OPTIONS = [
    { value: "user1" },
    { value: "user2" },
    { value: "user3" },
    { value: "admin0" }
];
