import React from "react";
import {
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {getTierDisplayValue} from "../../util/configDetailTable";

export const SALE_PREVIEW_FIELDS_HTML = [
    entry => (
        <div class={"brand"}>
            {entry["brand"]}
        </div>
    ),
    entry => (
        <div class={"name"}>
            {`${entry["name"]}`}
        </div>
    ),
    entry => entry["packageSize"] ? (
        <div class={"packageSize"}>
            {`PackageSize: ${entry["packageSize"]}`}
        </div>
    ) : null,
    entry => (
        <div class={"regPrice"}>
            {`${entry["regPriceStr"]}`}
        </div>
    ),
    entry => (
        <div>
            <SpaceBetween direction="horizontal" size="xs">
                <img
                    src={`${process.env.PUBLIC_URL}/images/SALE.png`}
                    alt=" "
                    width={50}
                    height={20}
                    class={"promoImage"}
                />
                <div class={"salePrice"}>
                    {entry["salePriceStr"]}
                </div>
            </SpaceBetween>
        </div>
    ),
    entry => entry["primePriceString"] ? (
        <div class={"primePrice"}>
            <span class={"primePriceColour"}>prime</span>
            {` ${entry["primePriceString"]}`}
        </div>
    ) : null,
    entry => (
        <div class={"valid"}>
            {`Valid ${entry["startDate"]} - ${entry["endDate"]}`}
        </div>
    )
];

export const SALE_PREVIEW_ITEMS = [
    entry => (
        <div class={"tier"}>
            {getTierDisplayValue(`${entry["tier"]}`)}
        </div>
    ),
    entry => entry["romanceCopyString"] ? (
        <div class={"romanceCopyString"}>
            {`RomanceCopy:${entry["romanceCopyString"]}`}
        </div>
    ) : null,
    entry => entry["headlineString"] ? (
        <div class={"headlineString"}>
            {`Headline:${entry["headlineString"]}`}
        </div>
    ) : null,
    entry => (
        <div class={"inStoreFlg"}>
            {`${entry["inStoreFlg"]}`=== "1" ? "IN-STORE SALE" : "" }
        </div>
    )
]