import React from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    Button,
    Header,
    SpaceBetween,
    Table,
    TextFilter,
    StatusIndicator
} from "@amzn/awsui-components-react/polaris";
import EmptyState from "./EmptyState";
import isEqual from "lodash.isequal";

export default function DetailCollection(props) {
    var details = props.details;
    var columnDefinitions = props.columnDefinitions;
    var editButton = props.editButton;
    var editControls = props.editControls;
    var editFooter = props.editFooter;
    var loading = props.loading;

    var visibleColumns = columnDefinitions.map(column => {
        return column.id;
    });

    const { items, actions, filteredItemsCount, collectionProps, filterProps } = useCollection(
        details,
        {
            filtering: {
                fields: visibleColumns,
                empty: loading ? (
                    <StatusIndicator type="loading">
                        Loading
                    </StatusIndicator>
                ) : (
                    <EmptyState
                        title="No details"
                        subtitle="No details to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="No matches to display."
                        action={<Button onClick={() => actions.setFiltering("")}>Clear filter</Button>}
                    />
                )
            },
            sorting: {}
        }
    );

    let onSortingChange = collectionProps.onSortingChange;
    let newOnSortingChange = (input => {
        if (!input.detail.isDescending &&
            isEqual(input.detail.sortingColumn, collectionProps.sortingColumn)) {
            input.detail.sortingColumn = {};
        }
        onSortingChange(input);
    });
    collectionProps.onSortingChange = newOnSortingChange;

    if (editControls && !(isEqual(collectionProps.sortingColumn, {}) || !collectionProps.sortingColumn)) {
        collectionProps.onSortingChange({detail: {sortingColumn: collectionProps.sortingColumn}});
    }

    return (
        <SpaceBetween direction="vertical" size="xs">
            <Table
                {...collectionProps}
                stickyHeader={true}
                header={
                    <Header
                        counter={loading ? null : ` (${details.length})`}
                        actions={editButton}
                    >
                        Promotions
                    </Header>
                }
                footer={editFooter}
                columnDefinitions={columnDefinitions}
                visibleColumns={visibleColumns}
                items={items}
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={<div>{`${filteredItemsCount} ${filteredItemsCount !== 1 ? "matches" : "match"}`}</div>}
                        filteringAriaLabel="Filter instances"
                        filteringPlaceholder="Search list"
                    />
                }
            />
            {editControls}
        </SpaceBetween>
    );
}