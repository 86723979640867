import { StyleSheet } from "@react-pdf/renderer";

export const PUBLIC_SALE_IMAGE_PATH = "/images/SALE.png";

export const STYLES = StyleSheet.create({
    page: {
        paddingTop: 25,
        paddingBottom: 25,
        paddingHorizontal: 35,
    },
    header: {
        fontSize: 12,
        marginBottom: 25,
        textAlign: "center",
        color: "grey"
    },
    footer: {
        position: "absolute",
        fontSize: 12,
        bottom: 35,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
    entryList: {


    },
    imageNames: {
        fontSize: "8px"
    },
    entry: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        paddingBottom: "5px",
        borderBottom: "1px solid darkgrey",
        marginBottom: "5px",

    },
    bottomEntry: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    promoImage: {
        width: "auto",
        height: "100px",
        marginTop: "10px",
        marginBottom: "10px"
    },
    promoImageNotFound: {
        textAlign: "center",
        width: "auto",
        height: "100px",
        marginTop: "10px",
        marginBottom: "10px"
    },
    textArea: {
        whiteSpace: "pre-wrap",
        hyphens: "none",
        overflow: "break-word",
        flex: 1,
        height: "350px",
        marginLeft: "15px",
        paddingVertical: "5px",
        fontFamily: "Helvetica",
        fontSize: 12,

    },
    tier: {
        fontSize: 12,
    },
    brand: {

    },
    name: {
        fontSize: 12
    },
    romanceCopyString: {

    },
    headlineString: {

    },
    packageSize: {

    },
    promoLine: {
        display: "flex",
        flexDirection: "row",
        marginTop: "10px",
        fontSize: 18
    },
    saleTagImage: {
        width: "40px",
        height: "12px"
    },
    regPrice: {
    },
    salePrice: {
        fontSize: 12,
        marginLeft: "5px"
    },
    primePriceString: {
        fontSize: 12,
        marginLeft: "-5px"
    },
    valid: {
        color: "dimgrey"
    },
    inStoreFlg: {

    }
});