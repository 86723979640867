import React, {useEffect} from "react";
import CheckboxCell from "./promotionEditableComponents/CheckboxCell";
import {AUTOMATION_FLAGS_CONSTANTS} from "../util/configDetailTable";

export default function AutomationFlags(props){

    const [map, setMap] =React.useState(new Map());
    const [existingFlag, setExistingFlag] = React.useState(false);

    useEffect(() => {
        if (props.value !== undefined && props.value !== null && props.value !== '') {
            Object.entries(props.value).forEach(keyValuePair => {
                if (keyValuePair[0] === AUTOMATION_FLAGS_CONSTANTS.IS_WINE) {
                    setMap(new Map(Object.entries(props.value)));
                }
            })
        } else {
            props.logFlyerModification("edit", [{
                "id": props.id,
                "attribute": props.attribute,
                "value": props.defaultValue || {}
            }]);
            setMap(new Map(Object.entries(props.defaultValue)));
        }
    },[existingFlag])

    const intEquivalentValue = (value) => {
        switch (value) {
            case true:
                return 1;
            default:
                return 0;
        }
    }

    const automationFlagsModification = (updatedMap, updatedValue) => {
        if (updatedMap !== undefined && updatedMap !== null && updatedMap.size > 0 && updatedValue !== undefined && updatedValue !== null){
            const updatedNewMap = Object.fromEntries(updatedMap);
            Object.entries(updatedNewMap).forEach(keyValuePair => {
                if (keyValuePair[0] === "is_wine") {
                    updatedNewMap.is_wine = intEquivalentValue(updatedValue)
                    props.logFlyerModification("edit", [{
                        "id": props.id,
                        "attribute": props.attribute,
                        "value": updatedNewMap || {}
                    }]);
                    setMap(new Map(Object.entries(updatedNewMap)));
                }
            })
        }
        else{
            setExistingFlag(true);
        }
    }

    return (
        <CheckboxCell attribute={props.attribute}
                      id={props.id}
                      value = {props.value}
                      label = {AUTOMATION_FLAGS_CONSTANTS.IS_WINE}
                      automationFlagsModification={automationFlagsModification}
                      map={map}
                      defaultSelection={props.defaultSelection} />
    );
}
