import React, { Component } from "react";
import {
    Box,
    Button,
    Input,
    FormField,
    Modal,
    Select,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import { PROMO_TYPE, getUserFriendlyPromoType } from "../util/configDetailTable";

export default class PromoTypeEditModal extends Component {
    constructor(props) {
        super(props);
        let options = PROMO_TYPE.map(promo => {
            return {value:promo.value, label: `${promo.value}, For Ex: [${promo.example}]`}
        });
        this.state = {
            flyerEntryId: props.flyerEntryId,
            logFlyerModification: props.logFlyerModification,
            visible: false,
            promoType: null,
            promoTypeOptions: options,
            x: "",
            y: "",
            z: ""
        };
        this.reset = this.reset.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.flyerEntryId !== this.props.flyerEntryId) {
            this.setState({
                flyerEntryId: this.props.flyerEntryId
            });
        }
    }

    reset() {
        this.setState({
            visible: false,
            promoType: null,
            x: "",
            y: "",
            z: ""
        });
    }

    render() {
        let promoType = this.state.promoType;
        
        let submitDisabled = !promoType;
        if (promoType) {
            if (promoType.value.includes("X")) {
                let x = this.state.x;
                let parsedX = parseFloat(x);
                let parsedXWithDecimals = parsedX.toFixed(2);
                if (!x || !(parsedX > 0 && (parsedX.toString() === x || parsedXWithDecimals.toString() === x))) {
                    submitDisabled = true;
                }
            }

            if (promoType.value.includes("Y")) {
                let y = this.state.y;
                let acceptedYUnits = new Set(["lb", "ea"]);
                let parsedY = parseFloat(y);
                let parsedYWithDecimals = parsedY.toFixed(2);
                if (!y || !(acceptedYUnits.has(y) || (parsedY > 0 && (parsedY.toString() === y || parsedYWithDecimals.toString() === y)))) {
                    submitDisabled = true;
                }
            }
            if (promoType.value.includes("Z")) {
                let z = this.state.z;
                let parsedZ = parseFloat(z);
                let parsedZWithDecimals = parsedZ.toFixed(2);
                if (!z || !(parsedZ > 0 && (parsedZ.toString() === z || parsedZWithDecimals.toString() === z))) {
                    submitDisabled = true;
                }
            }
        }

        return (
            <div>
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        this.setState({visible: true});
                    }}
                >
                    Edit Master Promotion Price
                </Button>
                <Modal
                    onDismiss={() => {
                        this.reset();
                    }}
                    visible={this.state.visible}
                    closeAriaLabel="Close modal"
                    size="medium"
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    onClick={() => {
                                        this.reset();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={submitDisabled}
                                    onClick={() => {
                                        let promoTypeResult = {
                                            type: promoType.value,
                                            x: this.state.x,
                                            y: this.state.y,
                                            z: this.state.z
                                        }
                                        if (!promoTypeResult.y) {
                                            delete promoTypeResult.y;
                                        }
                                        if (!promoTypeResult.z) {
                                            delete promoTypeResult.z
                                        }
                                        this.state.logFlyerModification("edit", [{
                                                "id": this.state.flyerEntryId,
                                                "attribute": "uiPromoType",
                                                "value": promoTypeResult
                                            },
                                            {
                                                "id": this.state.flyerEntryId,
                                                "attribute": "promoTypeStr",
                                                "value": getUserFriendlyPromoType(promoType.value,
                                                    this.state.x, this.state.y, this.state.z),
                                            }]);
                                        this.reset();
                                    }}
                                >
                                    Confirm
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="Edit Master Promotion Price"
                >
                    <SpaceBetween direction="vertical" size="xs">
                        <FormField
                            label="Master Promotion Price"
                        >
                            <Select
                                selectedOption={promoType}
                                onChange={({ detail }) =>
                                    this.setState({
                                        promoType: detail.selectedOption,
                                        x: "",
                                        y: "",
                                        z: ""
                                    })
                                }
                                options={this.state.promoTypeOptions}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                        <FormField
                            label="Promotion Value X (always numeric)"
                        >
                            <Input
                                disabled={!promoType || !promoType.value.includes("X")}
                                value={this.state.x}
                                onChange={event =>
                                    this.setState({ x: event.detail.value })
                                }
                            />
                        </FormField>
                        <FormField
                            label="Promotion Value Y (can be numeric or one of these: “lb”, “ea”)"
                        >
                            <Input
                                disabled={!promoType || !promoType.value.includes("Y")}
                                value={this.state.y}
                                onChange={event =>
                                    this.setState({ y: event.detail.value })
                                }
                            />
                        </FormField>
                        <FormField
                            label="Promotion Value Z (always numeric)"
                        >
                            <Input
                                disabled={!promoType || !promoType.value.includes("Z")}
                                value={this.state.z}
                                onChange={event =>
                                    this.setState({ z: event.detail.value })
                                }
                            />
                        </FormField>
                    </SpaceBetween>
                </Modal>
            </div>
        );
    }
}
