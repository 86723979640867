import React, { Component } from "react";
import HomeTable from "./HomeTable";
import FlyerSearch from "./FlyerSearch";
import { Container, Header, SpaceBetween, Button } from "@amzn/awsui-components-react/polaris";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            version: null,
        }
    }

    // TODO: get flyers when user refreshes page
    componentDidMount() {
        fetch("./CHANGELOG.md")
            .then(response => response.text())
            .then(text => {
                let version = text.split("\n")[2].split(" ")[1];
                this.setState({version: version});
            });
        this.props.getFlyers("home");
    }

    render() {
        return (
            <Container>
                <SpaceBetween direction="vertical" size="xs">
                    <Header
                        actions={
                            <SpaceBetween direction="horizontal" size="m">
                                <FlyerSearch history={this.props.history}/>
                                <Button onClick={() => {
                                    this.props.history.push("/stores-hierarchy");
                                }}>
                                    Stores Hierarchy
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        <SpaceBetween direction="horizontal" size="xs">
                            FDGeT Home
                            {this.state.version ? (
                                <Button
                                    variant="link"
                                    onClick={() => {
                                        this.props.history.push("/change-log");
                                    }}
                                >
                                    {this.state.version}
                                </Button>
                            ): null}
                        </SpaceBetween>
                    </Header>
                    <HomeTable
                        {...this.props}
                        title="Weekly Sales Flyers Assigned To Me"
                        create={this.props.permission === "admin" || this.props.permission === "superuser"}
                    />
                </SpaceBetween>
            </Container>
        )
    }
}