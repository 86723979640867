import React, { useState, useEffect } from "react";
import { FLYER_HISTORY_VIEW_COLUMN_DEFINITIONS } from "./ConfigFlyerHistoryTable";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { Button, Header, Table, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import {API} from "aws-amplify";
import {API_NAME, GET_FLYER_HISTORY} from "../../util/constants";

export default function FlyerHistory(props) {

    const [loading, setLoading] = useState(true);
    const [flyerId, setFlyerId] = useState(props.match.params.id);
    const [flyerActions, setFlyerActions] = useState([]);
    const { items, collectionProps } = useCollection(
        flyerActions,
        {
            sorting: {}
        }
    );

    //call getFlyerHistory onInit & prepare flyerActions
    useEffect(() => {
        setFlyerId(props.match.params.id);
        getHistory();
    }, [])


    const getHistory = () => {

        let getHistoryRequest = prepareGetHistoryRequest();
        API.post(API_NAME, GET_FLYER_HISTORY, getHistoryRequest)
            .then(response => {
                setFlyerActions(response.flyerActions)
            })
            .catch(e => console.log(e))
            .finally(() => {
                setLoading(false);
        });
    }

    const prepareGetHistoryRequest = () => {
        let getHistoryRequest = {
            body: {
                flyerId: flyerId
            }
        };
        return getHistoryRequest;
    }



    //Rendering section
    return (
        <React.Fragment>
            <Header
                actions={
                    <SpaceBetween direction="horizontal" size="m">

                        <Button onClick={() => {
                            props.history.push(`/flyer/${flyerId}`);
                        }}>
                            Return to Flyer Details
                        </Button>

                        <Button onClick={() => {
                            props.history.push("/");
                        }}>
                            Return to Home
                        </Button>


                    </SpaceBetween>

                }
            >
            </Header>

            <Table {...collectionProps}
                   loading={loading}
                   loadingText="Loading flyer's history"
                   columnDefinitions={FLYER_HISTORY_VIEW_COLUMN_DEFINITIONS}
                   items={items}/>
        </React.Fragment>
    );

}