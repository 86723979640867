import React, {Component} from "react";
import ReactMarkdown from "react-markdown";
import { Button, Header, Container } from "@amzn/awsui-components-react/polaris";

export default class ChangeLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changeLog: null
        }
    }

    componentDidMount() {
        fetch("./CHANGELOG.md")
            .then(response => response.text())
            .then(text => {
                let changeLogContents = text.split("\n");
                changeLogContents.splice(0, 2);
                changeLogContents = changeLogContents.join("\n");
                this.setState({changeLog: changeLogContents});
            })
            .catch(err => console.log(err));
    }

    render() {
        return (
            <Container
                header={
                    <Header
                        actions={
                            <Button onClick={() => {
                                this.props.history.push("/");
                            }}>
                                Return to Home
                            </Button>
                        }
                    >
                        Changelog
                    </Header>
                }
            >
                <ReactMarkdown>{this.state.changeLog}</ReactMarkdown>
            </Container>
        );
    }
}