import React, { Component } from "react";
import {
    Button,
    Header,
    Icon,
    SpaceBetween,
} from "@amzn/awsui-components-react/polaris";
import DetailCollection from "./DetailCollection";
import ModifyCell from "./ModifyCell";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { v4 as uuidv4 } from "uuid";
import {
    DETAILS_VIEW_COLUMN_DEFINITIONS,
    DETAILS_EDIT_COLUMN_DEFINITIONS,
    getEditConstraint,
    getHighlightFields,
    IMAGE_URL_SPLITTER,
    TRIMMABLE_FIELDS,
} from "../util/configDetailTable";
import AutomationFlags from "./AutomationFlags";
import FlyerEntryImageUpload from "./FlyerEntryImageUpload";
import PromoTypeEditModal from "./PromoTypeEditModal";
import ItemSelectModal from "./ItemSelectModal";
import {API} from "aws-amplify";
import {API_NAME, UPDATE_FLYER_ENTRIES} from "../util/constants";
import DropDownCell from "./promotionEditableComponents/DropDownCell";
import CheckboxCell from "./promotionEditableComponents/CheckboxCell";
import {AUTOMATION_FLAGS_CONSTANTS, SALE_TYPE_CONSTANTS, TIER_CONSTANTS, getPromoTypeDisplayValue, getTierDisplayValue,
    PROMOTION_GROUPING_CONSTANTS, getPromotionGroupingDisplayValue} from "../util/configDetailTable";
import DatePickerCell from "./promotionEditableComponents/DatePickerCell";
import SalePriceCell from "./promotionEditableComponents/SalePriceCell";
import TextAreaCell from "./promotionEditableComponents/TextAreaCell";
import DeleteRowButton from "./DeleteRowButton";
import PrimePriceCell from "./promotionEditableComponents/PrimePriceCell";
import RegularPriceCell from "./promotionEditableComponents/RegularPriceCell";

export default class DetailTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: props.userId,
            flyerId: props.flyerId || null,
            flyerPeriod: props.flyerPeriod || null,
            preview: props.preview || false,
            storeId: props.storeId || null,
            originalDetails: props.details || [],
            modifiableDetails: !!props.details ? cloneDeep(props.details) : [],
            viewColumnDefinitions: props.viewColumnDefinitions || DETAILS_VIEW_COLUMN_DEFINITIONS,
            editColumnDefinitions: props.editColumnDefinitions || DETAILS_EDIT_COLUMN_DEFINITIONS,
            create: props.create || false,
            editControlsEnabled: props.editControlsEnabled || false,
            editing: props.editing || false,
            loading: props.loading || false,
            error: null,
            editWatcher: props.editWatcher || ((editStatus) => {}),
            onConfirm: props.onConfirm || ((payload) => {}),
            getDetails: props.getDetails || (() => {}),
            detailIdToRow: {},
            changeSet: {},
            constraintViolations: {},
            flyerType: props.flyerType || null
        };
        this.logFlyerModification = this.logFlyerModification.bind(this);
        this.mapDetailIdToRow = this.mapDetailIdToRow.bind(this);
        this.submitFlyerUpdate = this.submitFlyerUpdate.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.loading !== this.props.loading) ||
            (prevState.editing !== this.state.editing && this.state.editing) ||
            (prevProps.details !== this.props.details)) {
            let details = this.props.details || [];
            this.setState({
                flyerId: this.props.flyerId || null,
                loading: this.props.loading,
                storeId: this.props.storeId || null,
                originalDetails: details,
                modifiableDetails: cloneDeep(details),
                detailIdToRow: this.mapDetailIdToRow(details),
                flyerPeriod: this.props.flyerPeriod || null,
                changeSet: {},
                constraintViolations: {},
                editControlsEnabled: this.props.editControlsEnabled,
                flyerType: this.props.flyerType
            });
        }
    }

    submitFlyerUpdate() {
        // TODO: might need to be changed after select items is finished
        let entries = Object.values(this.state.changeSet).map(change => {
            let entry = cloneDeep(change);
            if (entry.add) {
                delete entry.add;
                delete entry.flyerEntryId;
            }
            for (let attribute of Object.keys(entry)) {
                if (TRIMMABLE_FIELDS.has(attribute)) {
                    entry[attribute] = entry[attribute].trim().replaceAll("\t", "");
                }
                if (attribute === "rank") {
                    entry[attribute] = entry[attribute] === "" ? -1 :
                        parseInt(entry[attribute].replace("$", ""), 10);
                }
                if (attribute === "imageUrl" && entry[attribute].includes(IMAGE_URL_SPLITTER)) {
                    // if imageUrl has form "localUrl ; s3Key" as defined in FlyerEntryImageUpload,
                    // only include the s3Key when submitting changes
                    entry[attribute] = entry[attribute].split(" ; ")[1];
                }
                if (attribute === "promotionImageUrl" && entry[attribute].includes(IMAGE_URL_SPLITTER)) {
                    entry[attribute] = entry[attribute].split(" ; ")[1];
                }
                if (entry[attribute] === "") {
                    // empty values are represented by a single space
                    entry[attribute] = " ";
                }
            }
            if (entry.hasOwnProperty("asins")) {
                entry.asins = entry.asins === " " ? [] : entry.asins.split(",").map(asin => asin.trim());
            }
            if (entry.hasOwnProperty("upcs")) {
                entry.upcs = entry.upcs === " " ? [] : entry.upcs.split(",").map(upc => upc.trim());
            }
            if (entry.uiPromoType && !entry.uiPromoType.y) {
                delete entry.uiPromoType.y;
            }
            if (entry.uiPromoType && !entry.uiPromoType.z) {
                delete entry.uiPromoType.z;
            }
            return entry;
        });

        let requestOptions = {
            body: {
                userId: this.state.userId,
                flyerId: this.state.flyerId,
                flyerEntries: entries
            }
        };

        API.post(API_NAME, UPDATE_FLYER_ENTRIES, requestOptions)
            .then(response => {
                if (response.success) {
                    this.state.getDetails();
                }
            })
            .catch(e => console.log(e));
    }

    mapDetailIdToRow(items) {
        let newDetailIdToRow = {};
        for (let i = 0; i < items.length; i++) {
            newDetailIdToRow[items[i].flyerEntryId] = i;
        }
        return newDetailIdToRow;
    }

    // add:
    //     payload = null or {}
    // delete:
    //     payload = {
    //         id: "id"
    //     }
    // edit:
    //     payload = [
    //         {
    //             id: "id1",
    //             attribute: "attribute1",
    //             value: "value1"
    //         },
    //         ...,
    //         {
    //             id: "idN",
    //             attribute: "attributeN",
    //             value: "valueN"
    //         }
    //     ]
    // Only operates on one row of the flyer detail table at a time, i.e. one flyer entry at time
    logFlyerModification(operation, payload) {
        let modItems = cloneDeep(this.state.modifiableDetails);
        let newChangeSet = cloneDeep(this.state.changeSet);
        let newConstraintViolations = cloneDeep(this.state.constraintViolations);
        let mappingUpdate = null;
        if (operation === "add") {
            let newEntry = {
                flyerEntryId: uuidv4(), // needed for tracking frontend changes, doesn't get sent to api
            };
            for (const viewColumnDefinition of this.state.viewColumnDefinitions) {
                newEntry[viewColumnDefinition.id] = "";
            }
            newConstraintViolations[newEntry.flyerEntryId] = new Set(["uiPromoType", "startDate", "endDate"]);
            modItems.push(newEntry);
            mappingUpdate = { detailIdToRow: this.mapDetailIdToRow(modItems) };
            newChangeSet[newEntry.flyerEntryId] = newEntry;
            newChangeSet[newEntry.flyerEntryId].add = true;
        }
        else if (operation === "delete") {
            let rowIndex = this.state.detailIdToRow[payload.id];
            modItems.splice(rowIndex, 1);
            mappingUpdate = { detailIdToRow: this.mapDetailIdToRow(modItems) };
            if (newChangeSet[payload.id] && newChangeSet[payload.id].add) {
                delete newChangeSet[payload.id];
            }
            else {
                newChangeSet[payload.id] = {flyerEntryId: payload.id, deleted: 1};
            }
            if (payload.id in newConstraintViolations) {
                delete newConstraintViolations[payload.id];
            }
        }
        else if (operation === "edit") {
            this.editOperation(payload);
            return;
        }
        this.setState({
            modifiableDetails: modItems,
            ...mappingUpdate,
            changeSet : newChangeSet,
            constraintViolations: newConstraintViolations
        });
    }

    editOperation(payload) {
        this.setState(function (state) {
            if (!payload.length) {
                return {};
            }
            let mappingUpdate = null;
            let rowIndex = state.detailIdToRow[payload[0].id]; // all items in payload will share the same id and rowIndex since they are in the same row
            let modItems = cloneDeep(state.modifiableDetails);
            let newChangeSet = cloneDeep(state.changeSet);
            let newConstraintViolations = cloneDeep(state.constraintViolations);

            // payload can contain multiple attributes for a single flyer entry
            for (let payloadItem of payload) {
                // log modification for flyer entry
                modItems[rowIndex][payloadItem.attribute] = payloadItem.value;
                if (!newChangeSet[payloadItem.id]) {
                    newChangeSet[payloadItem.id] = { flyerEntryId: payloadItem.id }
                }
                newChangeSet[payloadItem.id][payloadItem.attribute] = payloadItem.value;
                // if constraint is violated, log the violating flyer entry id and attribute
                if (!getEditConstraint(payloadItem.attribute, state)(payloadItem.value)){
                    if (!(payloadItem.id in newConstraintViolations)) {
                        newConstraintViolations[payloadItem.id] = new Set();
                    }
                    newConstraintViolations[payloadItem.id].add(payloadItem.attribute);
                }
                // if constraint was previously violated but is now ok, remove the logged violation
                else if (payloadItem.id in newConstraintViolations) {
                    newConstraintViolations[payloadItem.id].delete(payloadItem.attribute);
                    if (!newConstraintViolations[payloadItem.id].size) {
                        delete newConstraintViolations[payloadItem.id];
                    }
                }
            }
            return {
                modifiableDetails: modItems,
                ...mappingUpdate,
                changeSet : newChangeSet,
                constraintViolations: newConstraintViolations
            }
        })
    }

    getFlyerDate = (index) => {
        if (this.state.flyerPeriod && index <= 1 && index >= 0) {
            const dates = this.state.flyerPeriod.split(" To ");
            if (dates.length === 2) {
                return dates[index];
            }
        }
    }

    render() {
        let columnDefinitions = [];

        if (this.state.editing) {
            let modifiedColumnDefinitions = cloneDeep(this.state.editColumnDefinitions);
            for (let i = 0; i < modifiedColumnDefinitions.length; i++) {
                let column = modifiedColumnDefinitions[i];
                if (column.id === "imageUpload") {
                    column.cell = e => (
                        <FlyerEntryImageUpload
                            attribute="imageUrl"
                            value={e.imageUrl}
                            id={e.flyerEntryId}
                            fileType="IMAGE"
                            logFlyerModification={this.logFlyerModification}
                        />
                    );
                }
                else if (column.id === "promotionImageUpload") {
                    column.cell = e => (
                        <FlyerEntryImageUpload
                            attribute="promotionImageUrl"
                            value={e.promotionImageUrl}
                            id={e.flyerEntryId}
                            fileType="IMAGE"
                            logFlyerModification={this.logFlyerModification}
                        />
                    );
                }
                else if (column.id === "promoTypeEdit") {
                    column.cell = e => (
                        <PromoTypeEditModal
                            flyerEntryId={e.flyerEntryId}
                            logFlyerModification={this.logFlyerModification}
                        />
                    );
                }
                else if (column.id === "itemSelect") {
                    column.cell = e => (
                        <ItemSelectModal
                            text={`${e.name} ${e.brand}`}
                            storeId={this.state.storeId}
                            flyerEntryId={e.flyerEntryId}
                            // asins={e.asins ? e.asins.split(",").map(asin => asin.trim()) : []}
                            logFlyerModification={this.logFlyerModification}
                        />
                    );
                }
                else if (column.id === "asins") {
                    column.cell = e => (
                        <TextAreaCell attribute={column.id}
                                      value={e[column.id] !== null ? e[column.id] : null}
                                      id={e.flyerEntryId}
                                      logFlyerModification={this.logFlyerModification}
                                      readOnlyFlag={true}/>
                    );
                }
                else if (column.id === "promoTypeStr" || column.id === "imageUrl" || column.id === "promotionImageUrl") {
                    // pass, promoTypeStr or imageUrl columns should not be modifiable
                }
                else if (column.id === "type") {
                    column.cell = e => (
                      <DropDownCell attribute={column.id}
                                    value={!!e[column.id] ? e[column.id] : ""}
                                    id={e.flyerEntryId}
                                    logFlyerModification={this.logFlyerModification}
                                    defaultSelection={SALE_TYPE_CONSTANTS.DEFAULT_SELECTION}
                                    options={SALE_TYPE_CONSTANTS.OPTIONS}
                                    retrieveDisplayValue={getPromoTypeDisplayValue}/>
                    );
                }
                else if (column.id === "inStoreFlg") {
                    column.cell = e => (
                        <CheckboxCell attribute={column.id}
                                      label="in-store"
                                      value={e[column.id] !== null ? e[column.id] : null}
                                      id={e.flyerEntryId}
                                      logFlyerModification={this.logFlyerModification}
                                      defaultSelection={false} />
                    );
                }
                else if (column.id === "primeEligibility") {
                    column.cell = e => (
                        <CheckboxCell attribute={column.id}
                                      label="Prime Eligible"
                                      value={e[column.id] !== null ? e[column.id] : null}
                                      id={e.flyerEntryId}
                                      logFlyerModification={this.logFlyerModification}
                                      defaultSelection={true} />
                    );
                }
                else if (column.id === "automatedRegPriceFlg") {
                    column.cell = e => (
                        <CheckboxCell attribute={column.id}
                                      label="isAutomated"
                                      value={e[column.id] !== null ? e[column.id] : null}
                                      id={e.flyerEntryId}
                                      logFlyerModification={this.logFlyerModification}
                                      defaultSelection={true}
                        />
                    );
                }
                else if (column.id === "automationFlags") {
                    column.cell = e => (
                        <AutomationFlags attribute={column.id}
                                         value={e[column.id] !== null && e[column.id] !== undefined ? e[column.id] : null}
                                         id={e.flyerEntryId}
                                         logFlyerModification={this.logFlyerModification}
                                         defaultValue={AUTOMATION_FLAGS_CONSTANTS.DEFAULT_SELECTION}
                                         defaultSelection={false} />
                    );
                }
                else if (column.id === "tier") {
                    column.cell = e => (
                        <DropDownCell attribute={column.id}
                                      value={!!e[column.id] ? e[column.id] : ""}
                                      id={e.flyerEntryId}
                                      logFlyerModification={this.logFlyerModification}
                                      defaultSelection={TIER_CONSTANTS.DEFAULT_SELECTION}
                                      options={TIER_CONSTANTS.OPTIONS}
                                      retrieveDisplayValue={getTierDisplayValue}/>
                    );
                }
                else if (column.id === "salePriceStr") {
                    column.cell = e => (
                        <SalePriceCell attribute={column.id}
                                      value={!!e[column.id] ? e[column.id] : ""}
                                      id={e.flyerEntryId}
                                      logFlyerModification={this.logFlyerModification}
                                      masterPromotionPrice={!!e['promoTypeStr'] ? e['promoTypeStr'] : ""}/>
                    );
                }
                else if (column.id === "primePriceString") {
                    column.cell = e => (
                        <PrimePriceCell attribute={column.id}
                                       value={!!e[column.id] ? e[column.id] : ""}
                                       id={e.flyerEntryId}
                                       logFlyerModification={this.logFlyerModification}
                                       flyertype={e['type']}
                                       primeEligibility={e['primeEligibility']}
                                       />
                    );
                }
                else if (column.id === "regPriceStr") {
                    column.cell = e => (
                        <RegularPriceCell attribute={column.id}
                                        value={!!e[column.id] ? e[column.id] : ""}
                                        id={e.flyerEntryId}
                                        logFlyerModification={this.logFlyerModification}
                                        automatedRegPriceFlg={e['automatedRegPriceFlg']}
                        />
                    );
                }
                else if (column.id === "startDate") {
                    column.cell = e => (
                        <DatePickerCell attribute={column.id}
                                      value={e[column.id] !== null ? e[column.id] : null}
                                      id={e.flyerEntryId}
                                      logFlyerModification={this.logFlyerModification}
                                      defaultValue={this.getFlyerDate(0)} />
                    );
                }
                else if (column.id === "endDate") {
                    column.cell = e => (
                        <DatePickerCell attribute={column.id}
                                        value={e[column.id] !== null ? e[column.id] : null}
                                        id={e.flyerEntryId}
                                        logFlyerModification={this.logFlyerModification}
                                        defaultValue={this.getFlyerDate(1)} />
                    );
                }
                else if (column.id === "promotionGrouping") {
                    column.cell = e => (
                        <DropDownCell attribute={column.id}
                                      value={!!e[column.id] ? e[column.id] : ""}
                                      id={e.flyerEntryId}
                                      logFlyerModification={this.logFlyerModification}
                                      defaultSelection={PROMOTION_GROUPING_CONSTANTS.DEFAULT_SELECTION}
                                      options={PROMOTION_GROUPING_CONSTANTS.OPTIONS}
                                      retrieveDisplayValue={getPromotionGroupingDisplayValue}/>
                    );
                }
                else if (column.id === "upcs") {
                    column.cell = e => (
                        <TextAreaCell attribute={column.id}
                                        value={e[column.id] !== null ? e[column.id] : null}
                                        id={e.flyerEntryId}
                                        logFlyerModification={this.logFlyerModification}
                                        readOnlyFlag={false} />
                    );
                }
                else if (column.id === "disclaimer") {
                    column.cell = e => (
                        <TextAreaCell attribute={column.id}
                                      value={e[column.id] !== null ? e[column.id] : null}
                                      id={e.flyerEntryId}
                                      logFlyerModification={this.logFlyerModification}
                                      readOnlyFlag={false}/>
                    );
                }
                else if (column.id === "headlineString") {
                    column.cell = e => (
                        <TextAreaCell attribute={column.id}
                                      value={e[column.id] !== null ? e[column.id] : null}
                                      id={e.flyerEntryId}
                                      logFlyerModification={this.logFlyerModification}
                                      readOnlyFlag={false}/>
                    );
                }
                else if (column.id === "romanceCopyString") {
                    column.cell = e => (
                        <TextAreaCell attribute={column.id}
                                      value={e[column.id] !== null ? e[column.id] : null}
                                      id={e.flyerEntryId}
                                      logFlyerModification={this.logFlyerModification}
                                      readOnlyFlag={false}/>
                    );
                }
                else {
                    column.cell = e => (
                        <ModifyCell
                            attribute={column.id}
                            value={!!e[column.id] ? e[column.id] : ""}
                            id={e.flyerEntryId}
                            logFlyerModification={this.logFlyerModification}
                        />
                    );
                }
            }

            let deleteColumnDefinition = {
                id: "DeleteEntry",
                header: "Delete",
                cell: e => (
                    <DeleteRowButton
                        id={e.flyerEntryId}
                        logFlyerModification={this.logFlyerModification}
                    />
                )
            };

            modifiedColumnDefinitions.unshift(deleteColumnDefinition);
            columnDefinitions = modifiedColumnDefinitions;
        }
        else {
            let modifiedColumnDefinitions = cloneDeep(this.state.viewColumnDefinitions);
            let highlightFields = getHighlightFields(this.state.flyerType);
            for (let i = 0; i < modifiedColumnDefinitions.length; i++) {
                let column = modifiedColumnDefinitions[i];
                if (column.id in highlightFields) {
                    let cell = column.cell;
                    let borderColor = highlightFields[`${column.id}`];
                    column.cell = e => {
                        if (!e[`${column.id}`]) {
                            return (
                                <div style={{border: `2px solid ${borderColor}`}}>
                                    {`Missing ${borderColor === "red" ? "Required " : ""}Detail`}
                                </div>
                            );
                        }
                        return cell(e);
                    }
                }
            }
            columnDefinitions = modifiedColumnDefinitions;
        }

        var editButton = (this.state.editControlsEnabled && !this.state.editing) ? (
            <Button
                onClick={() => {
                    this.state.editWatcher(true);
                    this.setState({ editing: true })
                }}
                disabled={this.state.loading}
                variant="primary"
            >
                <SpaceBetween direction="horizontal" size="xs">
                    <Icon name="edit" size="normal" variant="normal" />
                    Edit Flyer
                </SpaceBetween>
            </Button>
        ) : null;

        let constraintsViolated = !isEqual(this.state.constraintViolations, {});
        let violationNotice = null;
        if (constraintsViolated) {
            let rowsWithViolations = Object.keys(this.state.constraintViolations).map(entryId => {
                return this.state.detailIdToRow[entryId] + 1; // add 1 for user readability
            }).sort();
            violationNotice = `Invalid data in row${rowsWithViolations.length === 1 ? "" : "s"}: ` +
                `${rowsWithViolations.join(", ")}`;
            const constraintIds = Object.keys(this.state.constraintViolations);
            for (const entryId of constraintIds) {
                if (this.state.constraintViolations[entryId].has("disclaimer")) {
                    violationNotice = `${violationNotice} , disclaimer cannot be greater than 350 characters.`;
                }
                if (this.state.constraintViolations[entryId].has("upcs")) {
                    violationNotice = `${violationNotice} , upcs should be separated with commas & should not end with a comma.`;
                }
            }
        }

        var editControls = (this.state.editControlsEnabled && this.state.editing) ? (
            <Header
                actions={
                    <SpaceBetween direction="vertical" size="xs">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                onClick={() => {
                                    this.state.editWatcher(false);
                                    this.setState({
                                        editing: false,
                                        modifiableDetails: cloneDeep(this.state.originalDetails),
                                        detailIdToRow: this.mapDetailIdToRow(this.state.originalDetails),
                                        changeSet: {},
                                        constraintViolations: {}
                                    })
                                }}
                            >
                                {isEqual(this.state.changeSet, {}) ? "Cancel" : "Discard Changes"}
                            </Button>
                            <Button
                                onClick={event => {
                                    event.preventDefault();
                                    this.state.editWatcher(false);
                                    if (this.state.preview) {
                                        this.state.onConfirm(this);
                                    }
                                    else {
                                        this.setState({
                                                editing: false,
                                                loading: true,
                                                originalDetails: this.state.modifiableDetails,
                                                modifiableDetails: []
                                            },
                                            this.submitFlyerUpdate);
                                    }
                                }}
                                variant="primary"
                                disabled={(!this.state.preview && isEqual(this.state.changeSet, {})) ||
                                    constraintsViolated}
                            >
                                {this.state.preview ? "Save Changes" : "Submit Changes"}
                            </Button>
                        </SpaceBetween>
                        {violationNotice}
                    </SpaceBetween>
                }
            />
        ) : null;

        var editFooter = (this.state.editControlsEnabled && this.state.editing) ? (
            <Button onClick={(event) => {
                event.preventDefault();
                this.logFlyerModification("add", {});
            }}>
                <SpaceBetween direction="horizontal" size="xs">
                    <Icon name="add-plus"/>
                    Add Entry
                </SpaceBetween>
            </Button>
        ) : null;

        return (
            <DetailCollection
                details={this.state.modifiableDetails}
                columnDefinitions={columnDefinitions}
                editButton={editButton}
                editControls={editControls}
                editFooter={editFooter}
                loading={this.state.loading}
            />
        );
    }
}
