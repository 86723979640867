import React, {useEffect} from "react";
import { Input } from "@amzn/awsui-components-react/polaris";

export default function SalePriceCell(props) {

    const [value, setValue] = React.useState(props.value);

    // below logic is to initialize/set salePriceStr with masterPromotionPrice if it's empty
    useEffect(() => {
        applySalePriceDefaultValue();
    }, [])

    //below logic is to update salePriceStr whenever masterPromotionPrice changed - applicable for empty salePriceStr only
    useEffect(() => {
        applySalePriceDefaultValue();
    }, [props.masterPromotionPrice])

    useEffect(() => {
        setValue(props.value);
    }, [props])

    const applySalePriceDefaultValue = () => {
        if ((!value || value === '' || value === ' ')
            && props.masterPromotionPrice) {
            setValue(props.masterPromotionPrice);
            logSalePrice(props.masterPromotionPrice)
        }
    }

    const updateSalePrice = (event) => {
        setValue(event.detail.value);
        logSalePrice(event.detail.value);
    }

    const logSalePrice = (updatedValue) => {
        props.logFlyerModification("edit", [{
            "id": props.id,
            "attribute": props.attribute,
            "value": updatedValue
        }])
    }

    return (
        <Input
            onChange={updateSalePrice}
            value={value}
        />
    );
}
