import React, { Component } from "react";
import {
    Button,
    Form,
    FormField,
    SpaceBetween, 
    StatusIndicator
} from "@amzn/awsui-components-react/polaris";
import {IMAGE_URL_SPLITTER} from "../util/configDetailTable";
import {API} from "aws-amplify";
import {API_NAME, GET_SIGNED_S3_URL_FOR_UPLOAD} from "../util/constants";

export default class FlyerEntryImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            attribute: props.attribute,
            value: props.value,
            fileType: props.fileType,
            image: null,
            imagePreview: null,
            isSelected: false,
            isSuccess: false,
            isFailure: false,
            errorMessage: null,
            logFlyerModification: props.logFlyerModification || (() => {}),
        };
        this.hiddenInputRef = React.createRef();
        this.focusRef = React.createRef();
        this.reset = this.reset.bind(this);
        this.handleImageSelect = this.handleImageSelect.bind(this);
        this.handleImageUpload = this.handleImageUpload.bind(this);
    }

    reset() {
        this.setState({
            image: null,
            errorMessage: null,
            isSuccess: false,
            isFailure: false,
            value: null,
            isSelected: false,
            imagePreview: null
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState({ id: this.props.id });
        }
    }

    componentWillUnmount() {
        this.reset();
    }

    handleImageSelect(selectedImage) {
        this.reset();
        this.setState({
            image: selectedImage,
            imagePreview: URL.createObjectURL(selectedImage),
            isSelected: true
        }, this.handleImageUpload);

    }

    handleImageUpload() {
        this.setState({
            isFailure: false,
            isSuccess: false
        });
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "");
        var flag = "";
        if (this.state.attribute==="imageUrl") {
            flag = "productImageUrl"
        }
        else if (this.state.attribute==="promotionImageUrl") {
            flag = "promotionImageUrl"
        }
        var key = flag +"."+ this.state.id + "." + this.state.image.name.split(".").pop();
        var requestOptions = {
            headers: myHeaders,
            // creating name of the key such as flyerEntryID.jpg
            body: {
                'key': key,
                fileType : this.state.fileType,
            },

        };

        const signedS3UrlInfoReq = API.post(API_NAME,
                    GET_SIGNED_S3_URL_FOR_UPLOAD,
                    requestOptions)
                    .then(response => response)
                    .catch(e => console.log(e));

        signedS3UrlInfoReq.then(signedS3UrlInfoReq => {
            fetch(`${signedS3UrlInfoReq.signedUploadS3Url}`, {
                method: 'PUT',
                body: this.state.image,
                // Explicitly need to specify this else default is application/xml which won't work here.
                headers: {'Content-Type': ''}
            }).then(res => {
                console.log('Success:', res);
                this.setState({
                    isSuccess: true
                })
                // logging in the form "localUrl ; s3Key"
                this.state.logFlyerModification("edit", [{
                    "id": this.state.id,
                    "attribute": this.state.attribute,
                    "value": `${this.state.imagePreview}${IMAGE_URL_SPLITTER}${key}`
                }]);
            })})
            .catch(reqErr => {
                console.error(reqErr)
                this.setState({
                    isFailure: true
                })
            })
        
        this.focusRef.current.focus();
    }

    render() {
        let uploading = this.state.isSelected && !(this.state.isSuccess || this.state.isFailure);
        return (
            <div tabIndex="-1" ref={this.focusRef} onBlur={this.reset}>
                <Form>
                    <FormField>
                        <SpaceBetween direction="vertical" size="xs">
                        <input
                            ref={this.hiddenInputRef}
                            id="chooseFlyerEntryImage"
                            type="file"
                            hidden
                            accept="image/jpeg, image/png"
                            onChange={(event) => {
                                if (event.target.files[0].size === 0) {
                                    this.reset();
                                    this.setState({
                                        isSuccess: false,
                                        isFailure: true,
                                        errorMessage: "Image is Zero Bytes"
                                    })
                                }
                                else{
                                    this.handleImageSelect(event.target.files[0])
                                }
                            }}
                        />
                            <Button
                                formAction="none"
                                disabled={uploading}
                                onClick={(event) => {
                                    this.hiddenInputRef.current.value = null;
                                    this.hiddenInputRef.current.click();
                                }}
                            >
                                Upload Image
                            </Button>
                            {uploading ? (
                                <StatusIndicator type="loading">
                                    Uploading Image
                                </StatusIndicator>
                            ) : null
                            }
                            {this.state.isSuccess ? (
                                <div>
                                File Uploaded Successfully
                                </div>
                            ) : null
                            }
                            {this.state.isFailure ? (
                                <div>
                                    File Upload Failure<br/>{this.state.errorMessage}
                                </div>
                            ) : null
                            }
                        </SpaceBetween>
                    </FormField>
                </Form>
            </div>
        );
    }
}
