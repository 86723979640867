export const JSON_HEADERS = {
    "Content-Type": "application/json",
    "Accept": "application/json"
};
export const API_NAME = 'WfmZooxFDGeTUIServiceLambda';

export const GET_FLYER_HISTORY = '/getFlyerHistory';
export const GET_FLYER_LIST_FOR_HOME_OR_SEARCH_PAGE = "/getFlyerListForHomePage";
export const GET_STORES_HIERARCHY = '/getStoresHierarchy';

export const GET_FLYER_DETAILS = "/getFlyerDetails";
export const GET_USERS_TO_ASSIGN = "/getUsersToAssign";
export const GET_SIGNED_S3_URL_FOR_UPLOAD = "/getSignedS3UrlForFileUpload";

export const GET_PRODUCTS = "/getProducts";
export const UPDATE_FLYER_ENTRIES = "/updateFlyerEntries";
export const SUBMIT_ACTION = "/submitAction";

export const CREATE_FLYER = "/createFlyer";
export const MCP_IMPORT_SUBMIT_ACTION = "/createFlyerFromMcp";

export const US_EAST_1 = "us-east-1";

export const FEDERATE_PROVIDER = "AmazonFederate";
export const SCOPE = "openid";
export const RESPONSE_TYPE = "code";

export const BETA_DOMAIN = "beta.ui.fdget.wfm.amazon.dev";
export const PROD_DOMAIN = "prod.ui.fdget.wfm.amazon.dev";

// LOCAL DEV SETUP CONFIG
export const LOCAL_DOMAIN = "localhost:3000";
// Set to the domain setup in your aws account. Find more details in README of this package.
export const LOCAL_COGNITO_DOMAIN = "";
export const LOCAL_USER_POOL_ID = "";
export const LOCAL_WEB_CLIENT_ID = "";
export const LOCAL_API_GATEWAY_ENDPOINT = "";

export function getCognitoDomain(hostname) {
    switch (hostname) {
        case PROD_DOMAIN:
            return "fdget-users-prod.auth.us-east-1.amazoncognito.com";
        case BETA_DOMAIN:
            return "fdget-users-beta.auth.us-east-1.amazoncognito.com";
        default:
            return LOCAL_COGNITO_DOMAIN;
    }
}

export function getRedirectUrl(hostname) {
    switch (hostname) {
        case PROD_DOMAIN:
            return "https://" + PROD_DOMAIN + "/";
        case BETA_DOMAIN:
            return "https://" + BETA_DOMAIN + "/";
        default:
            return "http://" + LOCAL_DOMAIN + "/";
    }
}

export function getUserPoolId(hostname) {
    switch (hostname) {
        case PROD_DOMAIN:
            return "us-east-1_MyZG7cPWK";
        case BETA_DOMAIN:
            return "us-east-1_iiyqWcMty";
        default:
            return LOCAL_USER_POOL_ID;
    }
}

export function getUserPoolWebClientId(hostname) {
    switch (hostname) {
        case PROD_DOMAIN:
            return "2unb9olmsip58p82v3g4mgdnhm";
        case BETA_DOMAIN:
            return "56ibndndh6slgbcv0o0imnbe3b";
        default:
            return LOCAL_WEB_CLIENT_ID;
    }
}

export function getAPIGEndpoint(hostname) {
    switch (hostname) {
        case PROD_DOMAIN:
            return "https://1q4p57o5a1.execute-api.us-east-1.amazonaws.com/prod";
        case BETA_DOMAIN:
            return "https://2kpz70ta70.execute-api.us-east-1.amazonaws.com/prod";
        default:
            return LOCAL_API_GATEWAY_ENDPOINT;
    }
}

