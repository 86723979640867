import React, { Component } from "react";
import FlyerFileUpload from "./FlyerFileUpload";
import {
    Box,
    Button,
    DatePicker,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import cloneDeep from "lodash.clonedeep";

export default class FlyerCreationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: props.user,
            name: "",
            description: "",
            startDate: "",
            endDate: "",
            page: 1
        };
        this.hiddenInputRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    handleChange(field, value) {
        this.setState({ [field]: value});
    }

    handleNext() {
        this.setState({ page: 2 });
    }

    handleBack() {
        this.setState({ page: 1 });
    }

    handleError() {
        this.setState({page: 4})
    }

    handleSubmit() {
        this.setState({ page: 3 });
    }

    render() {
        var incomplete = !(this.state.name && this.state.description && this.state.startDate && this.state.endDate);
        var content = null;
        var metadata = cloneDeep(this.state);
        delete metadata.page;
        if (this.state.page === 1) {
            content = (
                <Form
                    actions={
                        <Button disabled={incomplete ? true : false} onClick={this.handleNext}>Next</Button>
                    }
                    header={<Header>Flyer Information</Header>}
                >
                    <SpaceBetween direction="vertical" size="xs">
                        <Box>
                        <FormField
                            label="Name"
                        >
                            <Input
                                value={this.state.name}
                                onChange={event =>
                                    this.handleChange("name", event.detail.value)
                                }
                            />
                        </FormField>
                        </Box>
                        <FormField
                            label="Description"
                        >
                            <Input
                                value={this.state.description}
                                onChange={event =>
                                    this.handleChange("description", event.detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label="Start Date"
                        >
                            <DatePicker
                                onChange={event =>
                                    this.handleChange("startDate", event.detail.value)
                                }
                                value={this.state.startDate}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                        ? `, selected date is ${selectedDate}`
                                        : "")
                                }
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                            />
                        </FormField>
                        <FormField
                            label="End Date"
                        >
                            <DatePicker
                                onChange={event =>
                                    this.handleChange("endDate", event.detail.value)
                                }
                                value={this.state.endDate}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                        ? `, selected date is ${selectedDate}`
                                        : "")
                                }
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                            />
                        </FormField>
                    </SpaceBetween>
                </Form>
            )
        }
        else if (this.state.page === 2) {
            content = (
                <FlyerFileUpload
                    userId={this.state.userId}
                    handleBack={this.handleBack}
                    handleError={this.handleError}
                    handleSubmit={this.handleSubmit}
                    metadata={metadata}
                />
            )
        }
        else if (this.state.page === 3 || this.state.page === 4) {
            let statusMessage = this.state.page === 3 ? "Successfully Submitted" : "Creation Failed";
            // TODO: use csv required fields constant to build message
            let errorMessage = this.state.page === 4 ? 'Please ensure the csv contains the following columns: ' +
                '"Name", "Brand", "StartDate", "EndDate", "PromoType"' +
                '"PromoTypeValueX", "PromoTypeValueY", "PromoTypeValueZ"': null;
            content = (
                <SpaceBetween direction="vertical" size="xs">
                    {`Flyer ${statusMessage}`}
                    {errorMessage}
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={(event) => {
                            event.preventDefault();
                            this.setState({
                                name: "",
                                description: "",
                                startDate: "",
                                endDate: "",
                                page: 1
                            });
                        }}>
                            Create flyer
                        </Button>
                        <Button onClick={() => {
                            this.props.history.push("/");
                        }}>
                            Return to Home
                        </Button>
                    </SpaceBetween>
                </SpaceBetween>
            )
        }

        return (
            <form>
                {content}
            </form>
        );
    }
}