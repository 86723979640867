import React, { Component } from "react";
import {
    Button,
    ColumnLayout,
    Container,
    Header,
    SpaceBetween,
} from "@amzn/awsui-components-react/polaris";
import {
    SALE_PREVIEW_ITEMS,
    SALE_PREVIEW_FIELDS_HTML
} from "./configSalePreview";
import "./SalePreview.css";
import isEqual from "lodash.isequal";
import { saveAsPdf } from "./SalePreviewPdf";

export default class SalePreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saleEntries: props.location.state.saleEntries,
            flyerId: props.match.params.id,
            flyerPeriod: props.location.state.flyerPeriod
        };
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.location.state, this.props.location.state)) {
            this.setState({
                saleEntries: this.props.location.state.saleEntries,
                flyerId: this.props.match.params.id,
                flyerPeriod: this.props.location.state.flyerPeriod
            });
        }
    }

    render() {
        let saleEntries = (this.state.saleEntries || []).map(saleEntry => {
            return (
                <SpaceBetween direction="horizontal" size="l">
                    {!saleEntry.imageUrl || saleEntry.imageUrl === "s3 Path for Image not found" ?
                        "No Image" :<div class="promotionImage"><span>Promotion Image</span><img src={saleEntry.imageUrl} alt=" " width="" height="180"/></div>}
                    {!saleEntry.promotionImageUrl || saleEntry.promotionImageUrl === "s3 Path for Image not found" ?
                        "No Image" : <div className="newPromotionImage"><span>Flyer 2.0 Promo Image</span><img src={saleEntry.promotionImageUrl} alt=" " width="" height="180"/></div>}
                    <div>
                        {SALE_PREVIEW_FIELDS_HTML.map(getSalePreviewFieldHtml => {
                            return getSalePreviewFieldHtml(saleEntry);
                        })}
                    </div>
                    <div>
                        {SALE_PREVIEW_ITEMS.map(salePreviewItems => {
                            return salePreviewItems(saleEntry);
                        })}
                    </div>
                </SpaceBetween>
            );
        });

        return (
            <Container
                header={
                    <Header
                        description={
                            "Note: This Sale Preview is NOT a true rendering of how the sale items" +
                            " will appear on web and mobile."
                        }
                        actions={
                            <SpaceBetween direction="horizontal" size="m">

                                <Button onClick={async () => {
                                    await saveAsPdf(this.state);
                                }}>
                                    Download
                                </Button>

                                <Button onClick={() => {
                                    this.props.history.push(`/flyer/${this.state.flyerId}`);
                                }}>
                                    Return to Flyer
                                </Button>

                                <Button onClick={() => {
                                    this.props.history.push("/");
                                }}>
                                    Return to Home
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        Sale Preview
                    </Header>
                }
            >
                <ColumnLayout borders="horizontal" columns={1}>
                    {saleEntries}
                </ColumnLayout>
            </Container>
        );
    }
}