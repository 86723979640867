
export const COLUMN_DEFINITIONS = [
    {
        id: "name",
        header: "Name",
        cell: e => e.name,
        sortingField: "name"
    },
    {
        id: "startDate",
        header: "Start Date",
        cell: e => e.startDate,
        sortingField: "startDate"
    },
    {
        id: "endDate",
        header: "End Date",
        cell: e => e.endDate,
        sortingField: "endDate"
    },
    {
        id: "flyerType",
        header: "Type",
        cell: e => e.flyerType,
        sortingField: "flyerType"
    },
    {
        id: "reviewStatus",
        header: "Status",
        cell: e => e.reviewStatus,
        sortingField: "reviewStatus"
    },
    {
        id: "assignedUser",
        header: "Assignee",
        cell: e => e.assignedUser,
        sortingField: "assignedUser"
    }
];

export const PAGINATION_LABELS = {
    nextPageLabel: "Next page",
    previousPageLabel: "Previous page",
    pageLabel: pageNumber =>
        `Page ${pageNumber} of all pages`
};

export const COLLECTION_PREFERENCES = {
    title: "Preferences",
    confirmLabel: "Confirm",
    cancelLabel: "Cancel",
    pageSizePreference: {
        title: "Select page size",
        options: [
            { value: 10, label: "10 flyers" },
            { value: 20, label: "20 flyers" }
        ]
    }
};