import React, { Component } from "react";
import {
    Button,
    Form,
    FormField,
    Header,
    Icon,
    SpaceBetween,
    StatusIndicator
} from "@amzn/awsui-components-react/polaris";
import DetailTable from "./DetailTable";
import { parse } from "papaparse";
import cloneDeep from "lodash.clonedeep";
import {
    CSV_VIEW_COLUMN_DEFINITIONS,
    CSV_EDIT_COLUMN_DEFINITIONS,
    CSV_REQUIRED_FIELDS,
    getUserFriendlyPromoType,
    csvLabelToInternalLabelMapping
} from "../util/configDetailTable";
import { v4 as uuidv4 } from "uuid";
import {API} from "aws-amplify";
import {API_NAME, CREATE_FLYER} from "../util/constants";

const DEFAULT_PARSER_CONFIG = {
    header: true,
    transform: value => value.trim()
}

function UploadedFile(props) {
    return (
        <SpaceBetween direction="horizontal" size="xs">
            <Icon
                name="status-positive"
                size="normal"
                variant="success"
            />
            <SpaceBetween>
                <span>File: {props.file.name}</span>
                <span>Size: {props.file.size} bytes</span>
                <span>Last Modified: {props.file.lastModifiedDate.toString()}</span>
            </SpaceBetween>
            <Button
                iconName="close"
                variant="icon"
                onClick={props.onClick}
            />
        </SpaceBetween>
    )
}

export default class FlyerFileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            errorMessage: null,
            details: [],
            metadata: props.metadata,
            handleBack: props.handleBack,
            handleSubmit: props.handleSubmit,
            handleError: props.handleError,
            uploading: false,
            editing: false
        };
        this.hiddenInputRef = React.createRef();
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.handleFlyerUpload = this.handleFlyerUpload.bind(this);
        this.handleParseSuccess = this.handleParseSuccess.bind(this);
        this.handleParseError = this.handleParseError.bind(this);
        this.reset = this.reset.bind(this);
    }

    reset() {
        this.setState({
            file: null,
            errorMessage: null,
            details: [],
            editing: false
        });
    }

    handleFileSelect(selectedFile) {
        this.reset();
        parse(selectedFile, {
            ...DEFAULT_PARSER_CONFIG,
            complete: this.handleParseSuccess,
            error: this.handleParseError(),
            skipEmptyLines: true,
        });
    }

    handleParseSuccess(parseResult, file) {
        // TODO: filter out any non-required fields that could be included
        //       for now, assume good input or missing fields, but no extra headers
        // TODO: account for random empty lines
        if (!parseResult.errors.length) {
            let fields = new Set(parseResult.meta.fields);
            for (const requiredField of CSV_REQUIRED_FIELDS) {
                if (!fields.has(requiredField)) {
                    this.state.handleError();
                }
            }
            let formattedEntries = parseResult.data.map(entry => {
                let formattedEntry = {
                    flyerEntryId: uuidv4() // needed for tracking frontend changes, doesn't get sent to api
                };
                // TODO: clean this up
                for (const [key, value] of Object.entries(entry)) {
                    formattedEntry[csvLabelToInternalLabelMapping[key]] = value;
                }
                formattedEntry.promoTypeStr = getUserFriendlyPromoType(formattedEntry.promoType,
                    formattedEntry.x, formattedEntry.y, formattedEntry.z);
                formattedEntry.uiPromoType = {
                    type: formattedEntry.promoType,
                    x: formattedEntry.x,
                    y: formattedEntry.y,
                    z: formattedEntry.z
                }
                return formattedEntry;
            });
            this.setState({
                file: file,
                details: formattedEntries
            });
        }
        else {
            this.state.handleError();
        }
    }

    handleParseError(parseError, file) {
        this.setState({ errorMessage: parseError});
    }

    handleFlyerUpload() {
        this.setState({uploading: true}, () => {
            let formattedEntries = this.state.details.map(detail => {
                let entry = {
                    name: detail.name,
                    brand: detail.brand,
                    startDate: detail.startDate,
                    endDate: detail.endDate,
                    uiPromoType: detail.uiPromoType,
                    promoTypeStr: detail.promoTypeStr
                };
                if (!entry.uiPromoType.y) {
                    delete entry.uiPromoType.y;
                }
                if (!entry.uiPromoType.z) {
                    delete entry.uiPromoType.z;
                }
                return entry;
            })
            let requestOptions = {
                body: {
                    ...this.state.metadata,
                    uiFlyerEntries: formattedEntries
                }
            };

            API.post(API_NAME, CREATE_FLYER, requestOptions)
                .then(response => {
                    if (response.success) {
                        this.state.handleSubmit();
                    } else {
                        this.state.handleError();
                    }
                })
                .catch(e => console.log(e));
        });
    }

    render() {
        let uploadedFile = this.state.file ? (
            <>
                {"Unselecting the file or selecting a new file will delete all promotions currently in the table"}
                <UploadedFile
                    file={this.state.file}
                    onClick={this.reset}
                />
            </>
        ) : null;

        let previewContent = (
            <>
                <Header>Flyer Preview</Header>
                <DetailTable
                    history={this.props.history}
                    preview={true}
                    editControlsEnabled={true}
                    metadata={this.state.metadata}
                    details={this.state.details}
                    viewColumnDefinitions={CSV_VIEW_COLUMN_DEFINITIONS}
                    editColumnDefinitions={CSV_EDIT_COLUMN_DEFINITIONS}
                    create={true}
                    editWatcher={(editStatus) => {
                        this.setState({ editing: editStatus });
                    }}
                    onConfirm={(component) => {
                        this.setState({
                            flyerMetadata: component.state.metadata,
                            details: component.state.modifiableDetails
                        });
                        component.setState({
                            originalDetails: cloneDeep(component.state.modifiableDetails),
                            editing: false
                        })
                    }}
                />
            </>
        );

        return (
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            onClick={this.state.handleBack}
                        >
                            Back
                        </Button>
                        <Button
                            disabled={!this.state.details.length || this.state.editing || this.state.uploading}
                            onClick={(event) => {
                                event.preventDefault();
                                this.handleFlyerUpload();
                            }}
                            variant="primary"
                        >
                            {this.state.uploading ? (
                                <StatusIndicator type="loading">
                                    Uploading
                                </StatusIndicator>
                            ) : (
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Icon name="upload" size="normal" variant="normal" />
                                    Upload Flyer
                                </SpaceBetween>
                            )}
                        </Button>
                    </SpaceBetween>
                }
                header={<Header>Flyer Upload</Header>}
            >
                <SpaceBetween size="s">
                    <FormField
                        label={"Select a flyer csv file to upload."}
                    >
                        <input
                            ref={this.hiddenInputRef}
                            id="chooseFileInput"
                            type="file"
                            hidden
                            accept="text/csv"
                            onChange={(event) => {
                                this.handleFileSelect(event.target.files[0])
                            }}
                        />
                        <Button
                            formAction="none"
                            onClick={() => {
                                this.hiddenInputRef.current.value = null;
                                this.hiddenInputRef.current.click();
                            }}
                        >
                            <SpaceBetween direction="horizontal" size="xs">
                                <Icon name="search" size="normal" variant="normal" />
                                Choose File
                            </SpaceBetween>
                        </Button>
                    </FormField>
                    {uploadedFile}
                    {previewContent}
                </SpaceBetween>
            </Form>
        );
    }
}
