import React, {useEffect} from "react";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import {AUTOMATION_FLAGS_CONSTANTS} from "../../util/configDetailTable";

export default function CheckboxCell(props) {

    const [checked, setChecked] = React.useState(props.defaultSelection);

    useEffect(() => {
        if (props.attribute === "primeEligibility" || props.attribute === "inStoreFlg" ||
            props.attribute === "automatedRegPriceFlg"){
            if (props.value !== undefined && props.value !== null && props.value !== '') {
                setChecked(booleanEquivalentValue(props.value));
            } else {
                setChecked(props.defaultSelection);
                props.logFlyerModification("edit", [{
                    "id": props.id,
                    "attribute": props.attribute,
                    "value": intEquivalentValue(props.defaultSelection)
                }]);
            }
        }
        else if (props.attribute === "automationFlags") {
            if (props.map !== undefined && props.map !== null && props.map.size > 0) {
                setChecked(checkedValue(props.label));
            } else {
                setChecked(props.defaultSelection);
                props.automationFlagsModification(props.map,props.defaultSelection)
            }
        }

    }, [])

    useEffect(() => {
        if (props.attribute === "primeEligibility" || props.attribute === "inStoreFlg" ||
            props.attribute === "automatedRegPriceFlg"){
            if (props.value !== undefined && props.value !== null && props.value !== '') {
                setChecked(booleanEquivalentValue(props.value));
            }
            else {
                setChecked(props.defaultSelection);
            }
        }
        else if (props.attribute === "automationFlags") {
            if (props.map !== undefined && props.map !== null && props.map.size > 0) {
                setChecked(checkedValue(props.label))
            }
            else {
                setChecked(props.defaultSelection);
            }
        }

    }, [props])

    const updateSelection = (event) => {
        setChecked(event.detail.checked);
        if (props.attribute === "primeEligibility" || props.attribute === "inStoreFlg" ||
            props.attribute === "automatedRegPriceFlg"){
            props.logFlyerModification("edit", [{
                "id": props.id,
                "attribute": props.attribute,
                "value": intEquivalentValue(event.detail.checked)
            }]);
        }
        else if (props.attribute === "automationFlags"){
            props.automationFlagsModification(props.map, event.detail.checked);
        }
    }

    const checkedValue = (flag) => {
        switch(flag){
            case AUTOMATION_FLAGS_CONSTANTS.IS_WINE:
                return props.value.is_wine;
            default:
                return 0;
        }
    }

    const booleanEquivalentValue = (value) => {
        switch (value) {
            case 1:
                return true;
            default:
                return false;
        }
    }

    const intEquivalentValue = (value) => {
        switch (value) {
            case true:
                return 1;
            default:
                return 0;
        }
    }

    return (
        <Checkbox
            onChange={updateSelection}
            checked={checked}
        >
            {props.label}
        </Checkbox>
    );
}
