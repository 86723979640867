import React, {useEffect} from "react";
import { Button, Icon } from "@amzn/awsui-components-react/polaris";

export default function DeleteRowButton(props) {

    const [clicked, setClicked] = React.useState(false)
    const [id, setId] = React.useState(props.id);

    useEffect(() => {
        if (props.id !== id) {
            setClicked(false);
            setId(props.id);
        }
    }, [props.id]);

    return(
        <div
            onBlur={() => setClicked(false)}
        >
            {clicked ? (
                <Button
                    variant={"primary"}
                    wrapText={false}
                    onClick={(event) => {
                        event.preventDefault();
                        props.logFlyerModification("delete", {"id": id})
                    }}
                >
                    Delete&nbsp;
                </Button>
            ) : (
                <Button
                    wrapText={false}
                    onClick={(event) => {
                        event.preventDefault();
                        setClicked(true);
                    }}
                >
                    &nbsp;&nbsp;&nbsp;&nbsp;<Icon name="close"/>&nbsp;&nbsp;&nbsp;&thinsp;&thinsp;
                </Button>
            )}
        </div>
    )
}