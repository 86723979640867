import React, { useState } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    Button,
    CollectionPreferences,
    Header,
    Icon,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
    StatusIndicator
} from "@amzn/awsui-components-react/polaris";
import EmptyState from "./EmptyState";
import {
    COLLECTION_PREFERENCES,
    COLUMN_DEFINITIONS,
    PAGINATION_LABELS
} from "../util/configHomeTable";

export default function HomeTable(props) {
    let permission = props.permission;
    let flyers = props.flyers;
    let create = props.create || false;
    let title = props.title;

    let powerUser = permission === "admin" || permission === "superuser";

    let columnDefinitions = COLUMN_DEFINITIONS;

    const [preferences, setPreferences] = useState({
        pageSize: 10,
        visibleContent: columnDefinitions.map(column => {
            return column.id;
        })
    });

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        flyers,
        {
            filtering: {
                fields: columnDefinitions.map(column => column.sortingField),
                empty: props.loading ? (
                    <StatusIndicator type="loading">
                        Loading
                    </StatusIndicator>
                ) : (
                    <EmptyState
                        title="No flyers"
                        subtitle="No flyer to display."
                        action={ (powerUser && create) ?
                            <Button onClick={() => {
                                props.history.push("/create");
                            }}>
                                Create flyer
                            </Button>
                        : null }
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="No matches to display."
                        action={<Button onClick={() => actions.setFiltering("")}>Clear filter</Button>}
                    />
                )
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {}
        }
    );

    let redirectToFlyerPage = (selectEvent) => {
        props.history.push(`/flyer/${selectEvent.detail.selectedItems[0].flyerId}`);
    };
    collectionProps.onSelectionChange = redirectToFlyerPage;

    return (
        <Table
            {...collectionProps}
            selectionType="single"
            header={
                <SpaceBetween>
                    <Header
                        counter={props.loading ? null : ` (${flyers.length})`}

                        actions={(powerUser && create) ?
                            <SpaceBetween direction="horizontal" size="s">
                                <Button onClick={() => {
                                    props.history.push("/create-mcp");
                                }}>
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Icon name="edit" size="normal" variant="normal" />
                                        MCP Import
                                    </SpaceBetween>
                                </Button>
                                <Button variant="primary" onClick={() => {
                                    props.history.push("/create");
                                }} >
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Icon name="edit" size="normal" variant="normal" />
                                        Create Flyer
                                    </SpaceBetween>
                                </Button>
                            </SpaceBetween> : null
                        }
                    >
                        {title}
                    </Header>
                    Select a flyer to view its details
                </SpaceBetween>
            }
            columnDefinitions={columnDefinitions}
            visibleColumns={preferences.visibleContent}
            items={items}
            pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_LABELS} />}
            filter={
                <TextFilter
                    {...filterProps}
                    filteringFields={preferences.visibleContent}
                    countText=<div>{`${filteredItemsCount} ${filteredItemsCount > 1 ? "matches" : "match"}`}</div>
                    filteringAriaLabel="Filter instances"
                    filteringPlaceholder="Search list"
                />
            }
            preferences={
                <CollectionPreferences id="collectionPrefs"
                    {...COLLECTION_PREFERENCES}
                    preferences={preferences}
                    onConfirm={({ detail }) => setPreferences(detail)}
                />
            }
        />
    );
}