import React, {useState} from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import EmptyState from "./EmptyState";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {
    Button,
    CollectionPreferences,
    Header,
    Pagination,
    SpaceBetween,
    StatusIndicator,
    TextFilter
} from "@amzn/awsui-components-react";
import {
    COLLECTION_PREFERENCES,
    PAGINATION_LABELS,
    ITEM_SELECT_COLUMN_DEFINITIONS
} from "../util/configItemSelectCollection";

export default function ItemSelectCollection(props) {
    let onSelect = props.onSelect || (payload => {});
    let products = props.products;
    let columnDefinitions = props.columnDefintions || ITEM_SELECT_COLUMN_DEFINITIONS;
    let loading = props.loading;

    let columnIds = columnDefinitions.map(column => {
        return column.id;
    });

    let [preferences, setPreferences] = useState({
        pageSize: 10,
        visibleContent: columnIds
    });


    let [selectedItems, setSelectedItems] = useState([]);

    const {items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps} = useCollection(
        products,
        {
            filtering: {
                fields: columnDefinitions.map(column => column.sortingField),
                empty: loading ? (
                    <StatusIndicator type="loading">
                        Loading
                    </StatusIndicator>
                ) : (
                    <EmptyState
                        title="No items"
                        subtitle="No items to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering("")}>Clear filter</Button>}
                    />
                )
            },
            pagination: {pageSize: preferences.pageSize},
            sorting: {},
            selection: {}
        }
    );

    let originalOnSelectionChange = collectionProps.onSelectionChange;
    let captureItemsOnSelectionChange = (selectEvent) => {
        setSelectedItems(selectEvent.detail.selectedItems);
        onSelect(selectEvent.detail.selectedItems)
    };
    collectionProps.onSelectionChange = (event) => {
        captureItemsOnSelectionChange(event);
        originalOnSelectionChange(event);
    };
    collectionProps.selectedItems = selectedItems;

    return (
        <SpaceBetween direction="vertical" size="xs">
            <Table
                {...collectionProps}
                header={
                    <Header
                        counter={loading ? null :
                            (selectedItems.length ? ` (${selectedItems.length}/${products.length})`
                                : ` (${products.length})`)}
                    >
                        Items
                    </Header>
                }
                selectionType="multi"
                columnDefinitions={columnDefinitions}
                visibleColumns={preferences.visibleContent}
                items={items}
                pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_LABELS}/>}
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={<div>{`${filteredItemsCount} ${filteredItemsCount !== 1 ? "matches" : "match"}`}</div>}
                        filteringAriaLabel="Filter instances"
                        filteringPlaceholder="Search list"
                    />
                }
                preferences={
                    <CollectionPreferences id="collectionPrefs"
                                           {...COLLECTION_PREFERENCES}
                                           preferences={preferences}
                                           onConfirm={({detail}) => setPreferences(detail)}
                    />
                }
            />
        </SpaceBetween>
    );
}