import React, {useEffect} from "react";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import './DatePickerCell.css';

export default function DatePickerCell(props) {

    const [value, setValue] = React.useState('');

    useEffect(() => {
        if (props.value) {
            setValue(props.value);
        } else {
            setValue(props.defaultValue);
            props.logFlyerModification("edit", [{
                "id": props.id,
                "attribute": props.attribute,
                "value": props.defaultValue
            }]);
        }
    }, [])

    useEffect(() => {
        if (props.value) {
            setValue(props.value);
        } else {
            setValue(props.defaultValue);
        }
    }, [props])

    const updateSelectedDate = (event) => {

        setValue(event.detail.value);
        props.logFlyerModification("edit", [{
            "id": props.id,
            "attribute": props.attribute,
            "value": event.detail.value
        }]);
    }

    return (
            <DatePicker
                className="date-container"
                onChange={updateSelectedDate}
                value={value}
                openCalendarAriaLabel={selectedDate =>
                    "Choose Date" +
                    (selectedDate
                        ? `, selected date is ${selectedDate}`
                        : "")
                }
                nextMonthAriaLabel="Next month"
                placeholder="YYYY/MM/DD"
                previousMonthAriaLabel="Previous month"
                todayAriaLabel="Today"
            />
    );
}
