import React, { useState } from "react";
import { Auth } from "aws-amplify";
import AppRouter from "./AppRouter";
import {FEDERATE_PROVIDER} from "./util/constants";

export default function App() {
    const [user, updateUser, ] = useState({userId: ""});
    React.useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(cognitoUserData => {
                const idToken = cognitoUserData.getSignInUserSession().getIdToken();
                const userId = idToken.payload["identities"][0].userId.split("@")[0];
                updateUser({userId: userId});
            })
            .catch(() => {
                Auth.federatedSignIn({customProvider: FEDERATE_PROVIDER})
            });
    }, []);

    if (user && user.userId) {
        return (
            <div className='App'>
                <AppRouter
                    uid={user.userId}
                />
            </div>
        )
    }
    return null;
}