import React from "react";
import {Amplify, Auth} from "aws-amplify";
import ReactDOM from "react-dom";
import App from "./App";
import {
    API_NAME,
    US_EAST_1,
    SCOPE,
    RESPONSE_TYPE,
    getAPIGEndpoint,
    getCognitoDomain,
    getRedirectUrl,
    getUserPoolId,
    getUserPoolWebClientId
} from "./util/constants";

const hostname = window.location.hostname;

const oauth = {
    domain: getCognitoDomain(hostname),
    scope: [SCOPE],
    redirectSignIn: getRedirectUrl(hostname),
    redirectSignOut: getRedirectUrl(hostname),
    responseType: RESPONSE_TYPE
};

Amplify.configure({
    Auth: {
        region: US_EAST_1,
        mandatorySignIn: true,
        userPoolWebClientId: getUserPoolWebClientId(hostname),
        userPoolId: getUserPoolId(hostname),
        oauth: oauth
    },
    API: {
        endpoints: [
            {
                name: API_NAME,
                endpoint: getAPIGEndpoint(hostname),
                region: US_EAST_1,
                // Add Authorization header for API gateway to validate, otherwise the API will respond with 401 NOT AUTHORIZED
                custom_header: async () => {
                    return {
                        Authorization: `${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`
                    }
                }
            }
        ]
    }
});

ReactDOM.render(
    <App/>,
    document.getElementById("root")
);