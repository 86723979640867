import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
    Alert,
    Box,
    Button,
    Container,
    Form,
    FormField,
    Header,
    Icon,
    Input,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import { v4 as uuidv4 } from 'uuid';
import {API} from "aws-amplify";
import {API_NAME, GET_SIGNED_S3_URL_FOR_UPLOAD, MCP_IMPORT_SUBMIT_ACTION} from "../util/constants";

export default class McpFileImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: props.user,
            fileType: "MCPFILE",
            year: null,
            file: null,
            isSelected: false,
            isSuccess: false,
            isFailure: false,
            mcpKey: null,
            enableSubmit: false,
            permission: props.permission,
            errorMessage: null,
            alertVisible : false,
        };
        this.hiddenInputRef = React.createRef();
        this.reset = this.reset.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.submitAction = this.submitAction.bind(this);

    }

    reset() {
        this.setState({
            file: null,
            errorMessage: null,
            isSuccess: false,
            isFailure: false,
            isSelected: false,
            enableSubmit: false,
            alertVisible : false,
        });
    }

    handleChange(field, value) {
        this.setState({ [field]: value});
    }

    handleFileSelect(selectedFile){
        this.reset();
        this.setState({
            file: selectedFile,
            isSelected: true
        }, this.handleFileUpload);
    }

    handleFileUpload() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "");
        const fileId = uuidv4();
        var key = fileId  + "." + this.state.file.name.split(".").pop()
        var requestOptions = {
            headers: myHeaders,
            body: {
                'key': key,
                fileType : this.state.fileType,
            },
        };

        const signedS3UrlInfoReq = API.post(API_NAME,
                        GET_SIGNED_S3_URL_FOR_UPLOAD,
                        requestOptions)
                        .then(response => response)
                        .catch(e => console.log(e));

        signedS3UrlInfoReq.then(signedS3UrlInfoReq => {
            fetch(`${signedS3UrlInfoReq.signedUploadS3Url}`, {
                method: 'PUT',
                body: this.state.file,
                // Explicitly need to specify this else default is application/xml which won't work here.
                headers: {'Content-Type': ''}
            }).then(res => {
                console.log('Success:', res);
                this.setState({
                    isSuccess: true,
                    enableSubmit: true,
                    mcpKey: key
                })
            })})
            .catch(reqErr => {
                console.error(reqErr)
                this.setState({
                    isFailure: true
                })
            })
    }

    submitAction() {
        let requestOptions = {
            body: {
                userId: this.state.userId,
                year: this.state.year,
                mcpKey: this.state.mcpKey,
            }
        };
        API.post(API_NAME, MCP_IMPORT_SUBMIT_ACTION, requestOptions)
            .then(response => {
                console.log('Success:', response)
                if (response.success) {
                    this.props.history.push("/");
                }
                else{
                    this.setState({
                        errorMessage: response.message,
                        alertVisible : true
                    })
                }
            })
            .catch(e => console.log(e));
    }

    render() {

        let mcpImportCreation = <SpaceBetween size="l">
            <SpaceBetween direction="vertical" size="xs">
                <Box>
                    <FormField
                        label="Year"
                    >
                        <Input
                            value={this.state.year}
                            onChange={event =>
                                this.handleChange("year", event.detail.value)
                            }
                            type="number"
                        />
                    </FormField>
                </Box>
            </SpaceBetween>

            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            disabled={
                                !this.state.enableSubmit || !this.state.year
                            }
                            variant="primary"
                            onClick={(event) => {
                                event.preventDefault();
                                this.submitAction();
                            }}
                        >
                             Submit
                        </Button>

                    </SpaceBetween>
                }
                header={<Header>Flyer Upload</Header>}
            >
                <SpaceBetween size="l">
                    <FormField
                        label={"Select a flyer xlsx file to upload."}
                    >
                        <input
                            ref={this.hiddenInputRef}
                            id="chooseFileInput"
                            type="file"
                            hidden
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(event) => {
                                this.handleFileSelect(event.target.files[0])
                            }}
                        />
                        <Button
                            formAction="none"
                            onClick={() => {
                                this.hiddenInputRef.current.click();
                            }}
                        >
                            <SpaceBetween direction="horizontal" size="xs">
                                <Icon name="search" size="normal" variant="normal" />
                                Choose File
                            </SpaceBetween>
                        </Button>
                        {this.state.isSuccess ? (
                            <div>
                                File Uploaded Successfully
                            </div>
                        ) : null
                        }
                        {this.state.isFailure ? (
                            <div>
                                File Upload Failure
                            </div>
                        ) : null
                        }
                    </FormField>
                </SpaceBetween>

            </Form>

        </SpaceBetween>;

        let content = (
            <Container>
                <Alert
                    visible={this.state.alertVisible}
                    type="error"
                    dismissAriaLabel="Close alert"
                    header="Mcp Import Process Failed"
                >
                    {this.state.errorMessage}
                </Alert>
                <Header
                    actions={
                        <SpaceBetween direction="horizontal" size="m">
                            <Button onClick={() => {
                                this.props.history.push("/");
                            }}>
                                Return to Home
                            </Button>
                        </SpaceBetween>
                    }
                >
                    MCP Import
                </Header>
                <SpaceBetween direction="vertical" size="xs">
                        <Container
                            header={
                                <Header>
                                    Flyer Information
                                </Header>
                            }
                        >
                            {mcpImportCreation}
                        </Container>
                </SpaceBetween>
            </Container>

        )

        let unknownPermission = null;

        let redirect = (
            <Redirect to="/"/>
        )

        return this.state.permission === null ? unknownPermission :
            ((this.state.permission !== "admin" && this.state.permission !== "superuser") ? redirect : content);
    }
}