import React, { Component } from "react";
import { Input } from "@amzn/awsui-components-react/polaris";
import isEqual from "lodash.isequal";

export default class ModifyCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            clickedOn: false,
            attribute: props.attribute,
            id: props.id,
            logFlyerModification: props.logFlyerModification || (() => {})
        }
        this.inputRef = React.createRef();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps, this.props)) {
            this.setState({
                value: this.props.value,
                attribute: this.props.attribute,
                id: this.props.id
            })
        }
    }

    render() {
        return (
            <div>
                <div
                    hidden={!this.state.clickedOn && this.state.value !== ""}
                >
                    <Input
                        onBlur={() => {
                            let originalValue = this.state.value;
                            let trimmedValue = originalValue.trimLeft();
                            this.setState({ clickedOn: false, value: trimmedValue });
                            if (trimmedValue !== originalValue) {
                                this.state.logFlyerModification("edit", [{
                                    "id": this.state.id,
                                    "attribute": this.state.attribute,
                                    "value": trimmedValue
                                }]);
                            }
                        }}
                        ref={this.inputRef}
                        onChange={(event) => {
                            this.setState({ value: event.detail.value, clickedOn: true });
                            this.state.logFlyerModification("edit", [{
                                "id": this.state.id,
                                "attribute": this.state.attribute,
                                "value": event.detail.value
                            }]);
                        }}
                        value={this.state.value}
                        onKeyDown={(event) => {
                            if (event.detail.key === "Enter") {
                                event.preventDefault();
                                this.setState({ clickedOn: false })
                            }
                        }}
                    />
                </div>
                {!this.state.clickedOn ? (
                    <div
                        onClick={(event) => {
                            this.setState({ clickedOn: true },
                            this.inputRef.current.focus
                        )}}
                    >
                        {this.state.value}
                    </div>
                ) : null}
            </div>
        );
    }
}
