import React, {useEffect} from "react";
import { Input } from "@amzn/awsui-components-react/polaris";

export default function PrimePriceCell(props) {

    const [value, setValue] = React.useState(props.value);
    const [isDisabled, setIsDisabled] = React.useState(false);


    useEffect(() => {
        primeValidationCheck();
    }, [])

    useEffect(()=> {
        primeValidationCheck();
    }, [props.flyertype,props.primeEligibility,props.value])

    const primeValidationCheck = () => {
        if (props.flyertype === "NATIONAL_SALE" && props.primeEligibility === 1) {
            setIsDisabled(false);
            setValue(props.value);
        }
        else {
            setIsDisabled(true);
            setEmptyValue();
        }
    }

    const updatePrimePrice = (event) => {
        setValue(event.detail.value);
        logPrimePrice(event.detail.value);
    }

    const setEmptyValue = () => {
        if (props.value !== "") {
            setValue("");
            logPrimePrice("");
        }
    }

    const logPrimePrice = (updatedValue) => {
        props.logFlyerModification("edit", [{
            "id": props.id,
            "attribute": props.attribute,
            "value": updatedValue
        }])
    }

    return (
        <Input
            onChange={updatePrimePrice}
            value={value}
            disabled={isDisabled}
        />
    );
}
