import React, {useEffect} from "react";
import { Input } from "@amzn/awsui-components-react/polaris";

export default function RegularPriceCell(props) {

    const [value, setValue] = React.useState(props.value);
    const [isDisabled, setIsDisabled] = React.useState(false);


    useEffect(() => {
        regularPriceValidationCheck();
    }, [])

    useEffect(()=> {
        regularPriceValidationCheck();
    }, [props.automatedRegPriceFlg,props.value])

    const regularPriceValidationCheck = () => {
        if (props.automatedRegPriceFlg === 0) {
            setIsDisabled(false);
        }
        else {
            setIsDisabled(true);
            setValue(props.value);
        }
    }

    const updateRegularPrice = (event) => {
        setValue(event.detail.value);
        logRegularPrice(event.detail.value);
    }

    const logRegularPrice = (updatedValue) => {
        props.logFlyerModification("edit", [{
            "id": props.id,
            "attribute": props.attribute,
            "value": updatedValue
        }])
    }

    return (
        <Input
            onChange={updateRegularPrice}
            value={value}
            disabled={isDisabled}
        />
    );
}
