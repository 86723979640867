import React, { Component } from "react";
import {
    Box,
    Button,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import ItemSelectCollection from "./ItemSelectCollection";
import {lowercaseFirstLetter} from "../util/configItemSelectCollection";
import {API} from "aws-amplify";
import {API_NAME, GET_PRODUCTS} from "../util/constants";

export default class ItemSelectModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            flyerEntryId: props.flyerEntryId,
            items: [],
            selectedItems: [],
            text: props.text || "",
            storeId: props.storeId || null,
            // asins: props.asins || [],
            loading: false,
            logFlyerModification: props.logFlyerModification
        };
        // TODO: see if we can get asins that are already in the asins column,
        // for now, overwrite whatevers there with confirmed selections
        this.reset = this.reset.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.flyerEntryId !== this.props.flyerEntryId) {
            this.setState({
                flyerEntryId: this.props.flyerEntryId,
                text: this.props.text,
                storeId: this.props.storeId
            })
        }
        else if (prevProps.text !== this.props.text) {
            this.setState({text: this.props.text});
        }
    }

    reset() {
        this.setState({
            items: [],
            selectedItems: [],
            // asins: [],
            visible: false,
            loading: false
        });
    }

    render() {
        return (
            <div>
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        this.setState({visible: true, loading: true}, () => {
                            let requestOptions = {
                                body: {
                                    text: this.state.text
                                }
                            };
                            if (this.state.storeId) {
                                requestOptions.store = this.state.storeId;
                            }
                            API.post(API_NAME, GET_PRODUCTS, requestOptions)
                                .then(response => {
                                    let products = [];
                                    for (let product of response.productInfoListUi) {
                                        let entry = {};
                                        for (const [key, value] of Object.entries(product)) {
                                            entry[lowercaseFirstLetter(key)] = value;
                                        }
                                        products.push(entry);
                                    }
                                    this.setState({items: products, loading: false});
                                })
                                .catch(e => console.log(e));
                        });
                    }}
                >
                    Select Items
                </Button>
                <Modal
                    onDismiss={() => {
                        this.reset();
                    }}
                    visible={this.state.visible}
                    closeAriaLabel="Close modal"
                    size="medium"
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    onClick={() => {
                                        this.reset();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={this.state.loading || !this.state.selectedItems.length}
                                    onClick={() => {
                                        let itemSelections = {
                                            flyerEntryId: this.state.flyerEntryId,
                                            asins: this.state.selectedItems.map(selectedItem => selectedItem.asin).join(", ")
                                        };
                                        this.state.logFlyerModification("edit", [{
                                            id: itemSelections.flyerEntryId,
                                            attribute: "asins",
                                            value: itemSelections.asins
                                        }])
                                        this.reset();
                                    }}
                                >
                                    Confirm
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="Select Items"
                >
                    <ItemSelectCollection
                        products={this.state.items}
                        onSelect={(itemSelectionsList) => {
                            this.setState({selectedItems: itemSelectionsList})
                        }}
                        loading={this.state.loading}
                    />
                </Modal>
            </div>
        );
    }
}
