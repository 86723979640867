import React, {useEffect} from "react";
import {useState} from "react";
import {Button, Header, Table, Grid, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {
    REGIONS_VIEW_COLUMN_DEFINITIONS,
    PRICE_ZONE_VIEW_COLUMN_DEFINITIONS,
    STORE_VIEW_COLUMN_DEFINITIONS
} from "./ConfigHierarchyTables";
import {API} from "aws-amplify";
import {API_NAME, GET_STORES_HIERARCHY} from "../../util/constants";


export default function StoresHierarchy(props) {

    const [state, setState] = useState({
        regionsLoading: true,
        storesList: []
    });

    const { items, collectionProps } = useCollection(
        state.storesList,
        {
            sorting: {}
        }
    );

    //call getRegions onInit & prepare regionsList
    useEffect(() => {

        const queryParams = new URLSearchParams(props.location.search);
        const queryPriceZoneId = queryParams.get('pricezone');
        const queryFlyerId = queryParams.get('flyerId');

        API.post(API_NAME, GET_STORES_HIERARCHY, {})
            .then(response => {
                setState(prevState => {
                    return {...prevState, regionsList: response.regions}
                })
                if (queryPriceZoneId) {
                    filterPriceZones(response.regions, queryPriceZoneId, queryFlyerId);
                }
            })
            .catch(e => console.log(e))
            .finally(() => {
                setState(prevState => {
                    return {...prevState, regionsLoading: false};
                });
            });
    }, [])

    const filterPriceZones = (regions, priceZoneId, flyerId) => {

        regions.forEach((region) => {
            const filteredPriceZone = region.priceZones.filter(priceZone => priceZone.priceZoneId === priceZoneId)
            if (filteredPriceZone && filteredPriceZone.length > 0) {
                setState(prevState => {
                    return {
                        ...prevState,
                        selectedRegion: [region],
                        priceZonesList: region.priceZones,
                        selectedPriceZone: filteredPriceZone,
                        storesList: filteredPriceZone[0].stores,
                        flyerId: flyerId
                    }
                })
                return;
            }
        })
    }


    const updateSelectedRegion = (event) => {

        if (event.detail && event.detail.selectedItems
            && event.detail.selectedItems.length > 0) {
            let updatedRegion = event.detail.selectedItems[0];

            setState(prevState => {
                return {...prevState,
                    priceZonesList: updatedRegion.priceZones,
                    selectedRegion: [updatedRegion],
                    selectedPriceZone: [],
                    storesList: []};
            });
            if (updatedRegion.priceZones && updatedRegion.priceZones.length === 1) {
                setState(prevState => {
                    return {...prevState,
                        selectedPriceZone: [updatedRegion.priceZones[0]],
                        storesList: updatedRegion.priceZones[0].stores}
                })
            }
        }
    }

    const updateSelectedPriceZone = (event) => {

        if (event.detail && event.detail.selectedItems
            && event.detail.selectedItems.length > 0) {

            let updatedPriceZone = event.detail.selectedItems[0];
            setState(prevState => {
                return {
                    ...prevState,
                    storesList: updatedPriceZone.stores,
                    selectedPriceZone: [updatedPriceZone]
                }
            })

        }
    }

    //Rendering section
    return (
        <React.Fragment>
            <Header
                actions={
                    <SpaceBetween direction="horizontal" size="m">
                        {state.flyerId ? <Button onClick={() => {
                            props.history.push(`/flyer/${state.flyerId}`);
                        }}>
                            Return to Flyer Details
                        </Button>: null}

                        <Button onClick={() => {
                            props.history.push("/");
                        }}>
                            Return to Home
                        </Button>
                    </SpaceBetween>
                }
            >
            </Header>

            <Grid
                gridDefinition={[{colspan: 3}, {colspan: 9}]}
            >
                <div>
                    <Table columnDefinitions={REGIONS_VIEW_COLUMN_DEFINITIONS}
                           loading={state.regionsLoading}
                           items={state.regionsList}
                           selectedItems={state.selectedRegion} selectionType="single"
                           onSelectionChange={updateSelectedRegion}/>
                </div>

                <div>
                    <SpaceBetween direction="vertical" size="xl">

                    <Table columnDefinitions={PRICE_ZONE_VIEW_COLUMN_DEFINITIONS}
                           items={state.priceZonesList}
                           selectedItems={state.selectedPriceZone} selectionType="single"
                           onSelectionChange={updateSelectedPriceZone}/>


                    <Table {...collectionProps}
                           columnDefinitions={STORE_VIEW_COLUMN_DEFINITIONS}
                           items={items}/>
                    </SpaceBetween>
                </div>
            </Grid>
        </React.Fragment>
    );
}