export function lowercaseFirstLetter(text) {
    return text.charAt(0).toLowerCase() + text.slice(1);
}

export const COLLECTION_PREFERENCES = {
    title: "Preferences",
    confirmLabel: "Confirm",
    cancelLabel: "Cancel",
    pageSizePreference: {
        title: "Select page size",
        options: [
            { value: 10, label: "10 items" },
            { value: 20, label: "20 items" }
        ]
    }
};

export const PAGINATION_LABELS = {
    nextPageLabel: "Next page",
    previousPageLabel: "Previous page",
    pageLabel: pageNumber =>
        `Page ${pageNumber} of all pages`
};

export const ITEM_SELECT_COLUMN_DEFINITIONS = [
    {
        id: "asin",
        header: "ASIN",
        cell: e => e.asin,
        sortingField: "asin"
    },
    {
        id: "name",
        header: "Name",
        cell: e => e.name,
        sortingField: "name"
    },
    {
        id: "brand",
        header: "Brand",
        cell: e => e.brand,
        sortingField: "brand"
    },
    {
        id: "store",
        header: "Store",
        cell: e => e.store,
        sortingField: "store"
    },
    {
        id: "image",
        header: "Image",
        cell: e => e.imageThumbnail,
        sortingField: "image"
    }
];