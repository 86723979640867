import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import Home from "./components/Home";
import FlyerDetails from "./components/FlyerDetails";
import Create from "./components/Create";
import Search from "./components/Search";
import {API} from "aws-amplify";
import {API_NAME, GET_FLYER_LIST_FOR_HOME_OR_SEARCH_PAGE} from "./util/constants";
import StoresHierarchy from "./components/storesHierarchy/StoresHierarchy";
import FlyerHistory from "./components/flyerHistory/FlyerHistory";
import ChangeLog from "./components/changeLog/ChangeLog";
import SalePreview from "./components/salePreview/SalePreview";
import McpFileImport from "./components/McpFileImport"

export default class AppRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.uid,
            permission: "admin",
            flyers: [],
            loading: false
        };
        this.getFlyers = this.getFlyers.bind(this);
    }

    getFlyers(homeOrSearch, searchParams="") {
        this.setState({flyers: [], loading: true}, () => {
            let requestOptions = {
                body: {
                    userId: this.state.user
                }
            };
            if (searchParams !== "") {
                requestOptions.body.searchParams = searchParams;
            }
            API.post(API_NAME, GET_FLYER_LIST_FOR_HOME_OR_SEARCH_PAGE, requestOptions)
                .then(response => {
                    this.setState({flyers: response.flyerList, loading: false});
                })
                .catch(e => console.log(e));
        });
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route
                        exact path="/"
                        render={props => (
                            <Home {...props} {...this.state} getFlyers={this.getFlyers}/>
                        )}
                    />
                    <Route
                        exact path="/flyer/:id"
                        render={props => (
                            <FlyerDetails {...props} {...this.state}/>
                        )}
                    />
                    <Route
                        path="/create"
                        render={props => (
                            <Create {...props} {...this.state}/>
                        )}
                    />
                    <Route
                        exact path="/create-mcp"
                        render={props => (
                            <McpFileImport {...props} {...this.state}/>
                        )}
                    />
                    <Route
                        path="/search"
                        render={props => (
                            <Search {...props} {...this.state} getFlyers={this.getFlyers}/>
                        )}
                    />
                    <Route
                        path="/stores-hierarchy"
                        render={props => (
                            <StoresHierarchy {...props} {...this.state}/>
                        )}
                    />
                    <Route
                        exact path="/flyer-history/:id"
                        render={props => (
                            <FlyerHistory {...props} {...this.state}/>
                        )}
                    />
                    <Route
                        exact path="/change-log"
                        render={props => (
                            <ChangeLog {...props} {...this.state}/>
                        )}
                    />
                    <Route
                        exact path="/sale-preview/:id"
                        render={props => (
                            <SalePreview {...props} {...this.state}/>
                        )}
                    />
                    <Redirect from="*" to="/" />
                </Switch>
            </Router>
        );
    }
}