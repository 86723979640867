import React, {Component} from "react";
import {
    Autosuggest,
    Button,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Input,
    Select,
    SpaceBetween,
    StatusIndicator
} from "@amzn/awsui-components-react";
import {
    NATIONAL_ACTION_OPTIONS,
    NON_NATIONAL_ACTION_OPTIONS
} from "../util/configActionMenu";
import {API} from "aws-amplify";
import {API_NAME, GET_USERS_TO_ASSIGN, SUBMIT_ACTION} from "../util/constants";
import cloneDeep from "lodash.clonedeep";

export default class ActionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flyerId: props.flyerId,
            flyerReviewStatus: props.flyerReviewStatus,
            flyerType: props.flyerType,
            isFlyerEditableByUser: props.isFlyerEditableByUser,
            assignee: "",
            action: {},
            reason: "",
            assigneeOptions: [],
            loading: false,
            uploading: false
        };
        this.submitAction = this.submitAction.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true}, () => {
            let requestOptions = {};
            API.post(API_NAME, GET_USERS_TO_ASSIGN, requestOptions)
                .then(response => {
                    let assigneeOptions = response.userList.map(userPair => {
                        return {value: `${userPair.name}, ${userPair.userId}`};
                    })

                    this.setState({assigneeOptions: assigneeOptions, loading: false});
                })
                .catch(e => console.log(e));
        });
    }

    submitAction() {
        this.setState({uploading: true}, () => {
            let requestOptions = {
                body: {
                    userId: this.props.userId,
                    flyerId: this.state.flyerId,
                    action: {
                        type: this.state.action.label.split(" ").join("_"),
                        assigneeId: this.state.assignee.split(", ")[1],
                        reason: this.state.reason
                    },
                }
            };
            API.post(API_NAME, SUBMIT_ACTION, requestOptions)
                .then(response => {
                    if (response.success) {
                        this.props.history.push("/");
                    }
                })
                .catch(e => console.log(e));
        });
    }

    render() {
        let actionOptions = this.props.flyerType === "National" ?
            NATIONAL_ACTION_OPTIONS : NON_NATIONAL_ACTION_OPTIONS;
        return (
            <Container
                header={
                    <Header>
                        Actions
                    </Header>
                }
                footer={
                    <Header
                        actions={
                            <Button
                                disabled={this.state.uploading || this.state.loading ||
                                    !this.state.reason || !Object.keys(this.state.action).length ||
                                    (this.state.action.label === "Assign Reviewer" &&
                                    !this.state.assigneeOptions.some(pair =>
                                        pair.value === this.state.assignee)) ||
                                    this.props.flyerLoading || this.props.editing
                                }
                                variant="primary"
                                onClick={this.submitAction}
                            >
                                {this.state.uploading ? (
                                    <StatusIndicator type="loading">
                                        Submitting
                                    </StatusIndicator>
                                ) : "Submit"}
                            </Button>
                        }
                    />
                }
            >
                {this.state.loading || this.props.flyerLoading ?
                    (<StatusIndicator type="loading">
                        Loading
                    </StatusIndicator>)
                    : (<SpaceBetween direction="vertical" size="xs">
                    <ColumnLayout columns={2}>
                        <FormField
                            label="Action"
                        >
                            <Select
                                selectedOption={this.state.action}
                                onChange={({detail}) => {
                                    this.setState({
                                        action: detail.selectedOption,
                                        assignee: ""
                                    })
                                }}
                                options={actionOptions.map(actionOption => {
                                    let copyOption = cloneDeep(actionOption);
                                    if (copyOption.label === "Create" &&
                                        this.props.flyerReviewStatus !== "InProgress") {
                                        copyOption["disabled"] = true;
                                    }
                                    else if (copyOption.label === "Approve" &&
                                        this.props.flyerReviewStatus === "InProgress" &&
                                        this.props.flyerType === "National") {
                                        copyOption["disabled"] = true;
                                        copyOption["label"] += " (Click Create before approval)";
                                    }
                                    else if (copyOption.label === "Approve" && this.props.missingRequiredData) {
                                        copyOption["disabled"] = true;
                                        copyOption["label"] += " (Required Details are Missing)";
                                    }
                                    else if (copyOption.label === "Delete" && this.props.flyerReviewStatus === "Approved" &&
                                        this.props.flyerType === "National" && this.props.isFlyerEditableByUser) {
                                        copyOption["disabled"] = true;
                                    }
                                    else if (copyOption.label === "Delete" && (this.props.flyerType === "Regional" ||
                                        this.props.flyerType === "Store" || this.props.flyerType === "RegionalStoreGroup")) {
                                        copyOption["disabled"] = true;
                                    }
                                    return copyOption;
                                })}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                        {this.state.action && this.state.action.label === "Assign Reviewer" ? (
                            <FormField
                                label="Assignee"
                            >
                                <Autosuggest
                                    onChange={({detail}) => {
                                        this.setState({assignee: detail.value})
                                    }}
                                    value={this.state.assignee}
                                    options={this.state.assigneeOptions}
                                    enteredTextLabel={value => value}
                                    ariaLabel="Autosuggest example with suggestions"
                                    placeholder="Enter user"
                                    empty="No matches found"
                                    loadingText="Loading"
                                    statusType={this.state.loading ? "loading" : ""}
                                />
                            </FormField>
                        ) : null}
                    </ColumnLayout>
                        <FormField
                            label="Reason"
                        >
                            <Input
                                value={this.state.reason}
                                onChange={event =>
                                    this.setState({reason: event.detail.value})
                                }
                            />
                        </FormField>
                </SpaceBetween>)}
            </Container>
        );
    }
}