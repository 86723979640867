import React from "react";
import {
    Document,
    Page,
    View,
    Text,
    Image,
    Font,
    pdf
} from "@react-pdf/renderer";
import {
    STYLES,
    PUBLIC_SALE_IMAGE_PATH
} from "./configSalePreviewPdf";
import {getTierDisplayValue} from "../../util/configDetailTable";
import { saveAs } from "file-saver";

export const saveAsPdf = async (props) => {
    const doc = getSalePreviewDocument(props.saleEntries);
    const docAsPdf = pdf(doc);
    const pdfAsBlob = await docAsPdf.toBlob();
    saveAs(pdfAsBlob, `Sale Preview ${props.flyerPeriod}.pdf`);
}

Font.registerHyphenationCallback(word => {
    // Return entire word as unique part instead of creating hyphens
    return [word];
});

export function getSalePreviewDocument(saleEntries) {
    return (
        <Document>
            <Page style={STYLES.page}>
                <Text
                    style={STYLES.header}
                    fixed={true}
                >
                    Note: This Sale Preview is NOT a true rendering of how the sale items will appear on web and mobile.
                </Text>
                <View style={STYLES.entryList}>
                    {saleEntries.map((saleEntry, index) => {
                        const { imageUrl, promotionImageUrl, tier, brand, name, packageSize,regPriceStr,romanceCopyString, headlineString,
                            salePriceStr, primePriceString, startDate, endDate, inStoreFlg } = saleEntry;
                        return (
                            <View
                                style={index % 5 !== 4 ? STYLES.entry : STYLES.bottomEntry}
                                wrap={false}
                            >
                                <View>
                                    <Text style={STYLES.imageNames}>
                                        Promotion Image
                                    </Text>
                                    {!imageUrl || imageUrl === " " ? (
                                        <Text style={STYLES.promoImageNotFound}>
                                            Image Not Found
                                        </Text>
                                    ) : (
                                        <Image
                                            style={STYLES.promoImage}
                                            src={imageUrl}
                                        />
                                    )}
                                    <Text style={STYLES.imageNames}>
                                        Flyer 2.0 Promo Image
                                    </Text>
                                    {!promotionImageUrl || promotionImageUrl === " " ? (
                                        <Text style={STYLES.promoImageNotFound}>
                                            Image Not Found
                                        </Text>
                                    ) : (
                                        <Image
                                            style={STYLES.promoImage}
                                            src={promotionImageUrl}
                                        />
                                    )}
                                </View>
                                <View style={STYLES.textArea}>
                                    <Text style={STYLES.brand}>
                                        {!!brand ? brand : " "}
                                    </Text>
                                    <Text style={STYLES.name}>
                                        {name}
                                    </Text>
                                    <Text style={STYLES.packageSize}>
                                        {packageSize}
                                    </Text>
                                    <Text style={STYLES.regPrice}>
                                        {`${regPriceStr}`}
                                    </Text>
                                    <View style={STYLES.promoLine}>
                                        <Image
                                            style={STYLES.saleTagImage}
                                            src={process.env.PUBLIC_URL + PUBLIC_SALE_IMAGE_PATH}
                                        />
                                        <Text style={STYLES.salePrice}>
                                            {salePriceStr}
                                        </Text>
                                    </View>
                                    <Text style={STYLES.primePriceString}>
                                        {!!primePriceString ?` Prime ${primePriceString}` : ""}
                                    </Text>
                                    <Text style={STYLES.tier}>
                                        {getTierDisplayValue(tier)}
                                    </Text>
                                    <Text style={STYLES.romanceCopyString}>
                                        {!!romanceCopyString ? `RomanceCopy: ${romanceCopyString}`: ""} {"\n"}

                                    </Text>
                                    <Text style={STYLES.headlineString}>
                                        {"\n"} {!!headlineString ? `Headline: ${headlineString}`: ""}
                                    </Text>
                                    <Text style={STYLES.inStoreFlg}>
                                        {inStoreFlg=== 1 ? "IN-STORE SALE" : ""}
                                    </Text>
                                    <Text style={STYLES.valid}>
                                        {`Valid ${startDate.split("-").slice(1).join("/")}` +
                                            ` - ${endDate.split("-").slice(1).join("/")}`}
                                    </Text>
                                </View>
                            </View>
                        );
                    })}
                </View>
                <Text
                    style={STYLES.footer}
                    render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )}
                    fixed={true}
                />
            </Page>
        </Document>
    );
}
