
export const REGIONS_VIEW_COLUMN_DEFINITIONS = [
    {
        id: "regionName",
        header: "Region",
        cell: e => e.regionName,
        sortingField: "regionName"
    }
];

export const PRICE_ZONE_VIEW_COLUMN_DEFINITIONS = [
    {
        id: "priceZoneId",
        header: "Price-Zone Id",
        cell: e => e.priceZoneId,
        sortingField: "priceZoneId"
    },
    {
        id: "priceZoneName",
        header: "Price-Zone",
        cell: e => e.priceZoneName,
        sortingField: "priceZoneName"
    }
];

export const STORE_VIEW_COLUMN_DEFINITIONS = [
    {
        id: "storeId",
        header: "Store-Id",
        cell: e => e.storeId,
        sortingField: "storeId"
    },
    {
        id: "storeName",
        header: "Store-Name",
        cell: e => e.storeName,
        sortingField: "storeName"
    },
    {
        id: "storeAddress",
        header: "Address",
        cell: e => prepareStoreAddress(e.storeAddress)
    },
    {
        id: "storeManager",
        header: "Manager",
        cell: e => e.storeManager? e.storeManager.name : '',
        sortingField: "storeManager"
    }
];

function prepareStoreAddress(uiStoreAddress) {

    if (uiStoreAddress) {
        return uiStoreAddress.city + ', ' + uiStoreAddress.state + ' ' + uiStoreAddress.zipCode + ' - ' + uiStoreAddress.country;
    }
}
