import React, {useEffect} from "react";
import Select from "@amzn/awsui-components-react/polaris/select";

export default function DropDownCell(props) {

    const acceptedValues = props.options.map(option => option.value);

    const [
        selectedOption,
        setSelectedOption
    ] = React.useState();

    useEffect(() => {
        if (props.value && acceptedValues.includes(props.value)) {
            setSelectedOption({label: props.retrieveDisplayValue(props.value), value: props.value})
        } else {
            setSelectedOption(props.defaultSelection);
            props.logFlyerModification("edit", [{
                "id": props.id,
                "attribute": props.attribute,
                "value": props.defaultSelection.value
            }]);
        }
    }, [])

    useEffect(() => {
        if (props.value && acceptedValues.includes(props.value)) {
            setSelectedOption({label: props.retrieveDisplayValue(props.value), value: props.value})
        } else {
            setSelectedOption(props.defaultSelection);
        }
    }, [props])

    const updateSelectedOption = (event) => {
        setSelectedOption(event.detail.selectedOption)
        props.logFlyerModification("edit", [{
            "id": props.id,
            "attribute": props.attribute,
            "value": event.detail.selectedOption.value
        }]);
    }

    return(
            <Select
                expandToViewport='true'
                selectedOption={selectedOption}
                onChange={updateSelectedOption}
                options={props.options}
                selectedAriaLabel="Selected"
            />
    )
}
