
export const FLYER_HISTORY_VIEW_COLUMN_DEFINITIONS = [
    {
        id: "actionDate",
        header: "Date",
        cell: e => e.actionDate,
        sortingField: "actionDate"
    },
    {
        id: "actionTime",
        header: "Time - UTC",
        cell: e => e.actionTime,
        sortingField: "actionTime"
    },
    {
        id: "ownerId",
        header: "User",
        cell: e => e.ownerId,
        sortingField: "ownerId"
    },
    {
        id: "action",
        header: "Action",
        cell: e => e.action,
        sortingField: "action"
    },
    {
        id: "description",
        header: "Description",
        cell: e => e.description,
        sortingField: "description"
    }
];
